import { RuleBase, RuleSpec, RulesEngineResult } from '../rule-base';

export class RuleAverageHoursOverYear extends RuleBase {
  test(): RulesEngineResult {
    return { status: 'ok' };
  }
  specs(): RuleSpec[] {
    return [
      {
        id: 'avaerge-hours-over-year',
        category: 'other',
        description:
          'Im Jahresdurchschnitt sollte ein Mitarbeiter {average} Stunden pro Woche arbeiten',
        defaults: [{ key: 'average', type: 'number', value: '38' }]
      }
    ];
  }
}
