import { RuleBase, RuleSpec, RulesEngineResult } from '../rule-base';

export class RuleAccountingPeriod extends RuleBase {
  test(): RulesEngineResult {
    return { status: 'ok' };
  }
  specs(): RuleSpec[] {
    return [
      {
        id: 'accounting-period',
        category: 'other',
        description: 'Rechnungszeitraum läuft vom {from} bis zum {to}',
        defaults: [
          { key: 'from', type: 'day', value: '01.08' },
          { key: 'to', type: 'day', value: '31:07' }
        ]
      }
    ];
  }
}
