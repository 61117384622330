import { Input, Button } from '@kidsmanager/ui-core';
import { useContext, useState } from 'react';
import { PasswordChecker } from '../components/password-checker';
import { ClientBackendContext } from '@kidsmanager/ui-api';

export const FeatureCredentialsPassword = () => {
  const [showRules, setShowRules] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [passwordValid, setPasswordValid] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [passwordError, setPasswordError] = useState('');

  const client = useContext(ClientBackendContext);

  const handleChangeConfirm = (value: string) => {
    setPasswordMatch(value === newPassword);
  };

  const handleBlurConfirm = () => {
    setPasswordError(
      passwordMatch
        ? ''
        : 'Passwort und Bestätigungspasswort sind unterschiedlich'
    );
  };

  const handlePasswordChange = async () => {
    await client.settings.changePassword(oldPassword, newPassword);
  };

  return (
    <div>
      <h1 className="mb-1 text-xl">Passwort &auml;ndern</h1>
      <div className="grid grid-cols-[400px_auto]">
        <div className="flex flex-col gap-2 px-4">
          <Input
            name="old-password"
            label="Aktuelles Passwort"
            type="password"
            autocomplete="old-password"
            onChange={setOldPassword.bind(this)}
          />
          <Input
            name="new-password"
            label="Neues Passwort"
            type="password"
            autocomplete="new-password"
            onFocus={setShowRules.bind(this, true)}
            onBlur={setShowRules.bind(this, false)}
            onChange={setNewPassword.bind(this)}
          />
          <Input
            name="confirm-password"
            label="Neues Passwort wiederholen"
            type="password"
            autocomplete="confirm-password"
            hint={passwordError}
            onChange={handleChangeConfirm.bind(this)}
            onBlur={handleBlurConfirm.bind(this)}
          />
          <div className="mt-4">
            <Button
              disabled={
                !passwordValid || !passwordMatch || oldPassword.length === 0
              }
              type="submit"
              onClick={handlePasswordChange.bind(this)}
            >
              Passwort &auml;ndern
            </Button>
          </div>
        </div>
        {showRules && (
          <PasswordChecker
            value={newPassword}
            onChange={setPasswordValid.bind(this)}
          />
        )}
      </div>
    </div>
  );
};
