import { AuthState } from '@kidsmanager/ui-api';

export const handleRefresh = (state: AuthState) => {
  if (state === 'Authenticated') {
    return state;
  }
  if (window.location.pathname.length > 1) {
    window.location.href = `/login?returnUrl=${window.location.pathname}`;
  } else {
    const tenant = new URLSearchParams(window.location.search).get('tenant');
    if (tenant) {
      window.location.href = `/login?tenant=${tenant}`;
    } else {
      window.location.href = `/login`;
    }
  }
  return state;
};
