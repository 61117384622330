import { Link } from 'react-router-dom';
import { HeaderAreas } from './header-areas';
import { HeaderGroupSelector } from './header-group-selector';
import { HeaderMenuItem } from './header-menu';
import { HeaderUserMenu } from './header-user-menu';
import { IconHelp } from './icons';

interface accessItem {
  title: string;
  href: string;
  selected?: boolean;
}
export interface HeaderProps {
  areas: accessItem[];
  admin: accessItem[];
  groups: accessItem[];
  user: {
    firstName: string;
    lastName: string;
    map: accessItem[];
  };
}

export const Header = (props: HeaderProps) => {
  return (
    props.groups &&
    props.admin &&
    props.areas && (
      <header className="bg-silver-25 border-y-silver-225 sticky top-0 z-50 flex min-h-[29px] items-center border-b">
        {props.groups.length > 0 && (
          <HeaderGroupSelector groups={props.groups} />
        )}
        <HeaderAreas areas={props.areas} />
        <div className="text-silver-900 text-hdr2 flex items-center">
          <span className="pr-[3px] font-bold">
            {props.user?.lastName}, {props.user?.firstName}
          </span>
          {props.admin.length > 0 && (
            <HeaderUserMenu title="AdministratorIn">
              {props.admin?.map((option) => (
                <HeaderMenuItem
                  key={option.title}
                  title={option.title}
                  href={option.href}
                />
              ))}
            </HeaderUserMenu>
          )}
          <span>|</span>
          <HeaderUserMenu title="Einstellungen">
            {props.user.map.map((option) => (
              <HeaderMenuItem
                key={option.title}
                title={option.title}
                href={option.href}
              />
            ))}
          </HeaderUserMenu>
          <span>|</span>
          <span className="px-1">
            <Link className="text-hdr2 outline-focus" to="/logout">
              Ausloggen
            </Link>
          </span>
          <span className="cursor-pointer pr-1">
            <IconHelp />
          </span>
        </div>
      </header>
    )
  );
};
