import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

export interface TabsProps {
  basePath: string;
  tabs: { path: string; title: string }[];
  children: React.ReactNode;
  onChange?: (path: string) => void;
}

export const Tabs = (props: TabsProps) => {
  const location = useLocation();
  const [selected, setSelected] = useState(props.tabs[0].path);

  useEffect(() => {
    const last = location.pathname.split('/').pop();
    if (!last) {
      const path = props.tabs[0].path;
      setSelected(path);
      props.onChange?.(path);
    } else {
      const match = props.tabs.find((tab) => tab.path.endsWith(last));
      const path = match ? match.path : props.tabs[0].path;
      setSelected(path);
      props.onChange?.(path);
    }
  }, [location, props.tabs, props]);

  return (
    <div className="px-2">
      <nav className="my-4 flex gap-6">
        {props.tabs.map((tab) => (
          <Link
            key={tab.path}
            className={`border-silver-800 border-b-4 px-4 ${selected === tab.path ? 'opacity-100' : 'opacity-50 hover:opacity-75'}`}
            to={`${props.basePath}/${tab.path}`}
          >
            {tab.title}
          </Link>
        ))}
      </nav>
      {props.children}
    </div>
  );
};
