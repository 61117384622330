import lottie from 'lottie-web';
import animation from './netwons_cradle.json';
import { useContext, useEffect, useRef } from 'react';
import { ClientBackendContext } from '@kidsmanager/ui-api';
import { linkWithLegacy } from '../helpers/linking';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const LegacyRedirect = () => {
  const ref = useRef<HTMLDivElement>(null);
  const client = useContext(ClientBackendContext);
  const navigate = useNavigate();
  const [params] = useSearchParams();

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    ref.current.innerHTML = '';
    lottie.loadAnimation({
      name: 'thanks',
      animationData: animation,
      autoplay: true,
      container: ref.current,
      loop: -1,
      renderer: 'svg'
    });

    if (!client.auth.isAuthenticated()) {
      navigate('/login');
    }

    const returnUrl = params.get('returnUrl') || '/';
    if (sessionStorage.getItem('tenant') === 'dev') {
      setTimeout(() => {
        navigate(returnUrl);
      }, 1000);
    } else {
      client.apiOauth2.link().then(({ tenant, otp }) => {
        const origin = window.location.origin;
        const singleUser = localStorage.getItem('sso-single-user') === 'true';
        linkWithLegacy(tenant, otp, origin, singleUser, returnUrl, () => {
          window.location.replace(`https://${tenant}.kidsmanager.at`);
        });
      });
    }
  }, [client, navigate, params]);

  return (
    <div className="mt-16 flex flex-col items-center">
      <div ref={ref} className="flex min-h-96 w-1/2 max-w-lg items-center" />
      <p className="text-black/50">Verbindung wird hergestellt</p>
    </div>
  );
};
