export interface IAccessItem {
  title: string;
  href: string;
  id?: string;
}

export interface IAccessMap {
  areas: IAccessItem[];
  team: IAccessItem[];
  groups: IAccessItem[];
  admin: IAccessItem[];
  user: {
    firstName: string;
    lastName: string;
    currentGroup?: string;
    map: IAccessItem[];
  };
}

export const emptyAccessMap: IAccessMap = {
  areas: [],
  team: [],
  groups: [],
  admin: [],
  user: { firstName: '', lastName: '', currentGroup: '', map: [] }
};

export interface IProfile {
  currentGroup: string;
  mfaDeivceRegistered: boolean;
}
