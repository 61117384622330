import { ClientBackendContext } from '@kidsmanager/ui-api';
import { Button } from '@kidsmanager/ui-core';
import { useContext, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const FeatureEmailConfirmed = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const client = useContext(ClientBackendContext);

  useEffect(() => {
    const tenant = searchParams.get('tenant');
    const token = searchParams.get('token');
    if (!tenant || !token) {
      navigate('/');
      return;
    }
    client.auth.verifyEmail(tenant, token);
  }, [client, searchParams, navigate]);

  return (
    <div className="pt-32 text-center">
      <div className="pb-2 text-center text-4xl">
        <span className="font-bold">Kids</span>Manager
      </div>
      <div className="m-1 inline-grid grid-cols-2 gap-[2px]">
        <div className="bg-km-green flex h-24 w-24 items-center justify-center">
          <svg
            viewBox="0 0 20 16"
            xmlns="http://www.w3.org/2000/svg"
            width={75}
          >
            <g transform="translate(-2 -4)">
              <path
                d="M4,20C3.45,20 2.979,19.804 2.588,19.413C2.196,19.021 2,18.55 2,18L2,6C2,5.45 2.196,4.979 2.588,4.587C2.979,4.196 3.45,4 4,4L20,4C20.55,4 21.021,4.196 21.413,4.587C21.804,4.979 22,5.45 22,6L22,18C22,18.55 21.804,19.021 21.413,19.413C21.021,19.804 20.55,20 20,20L4,20ZM12,13L20,8L20,6L12,11L4,6L4,8L12,13Z"
                fill="#fff"
              />
            </g>
          </svg>
        </div>
        <div className="bg-km-orange h-24 w-24" />
        <div className="bg-km-blue h-24 w-24" />
        <div className="bg-km-pink h-24 w-24" />
      </div>
      <p className="mb-5 mt-10 text-lg">Danke! Deine E-Mail wurde bestätigt</p>
      <Button className="px-6" onClick={() => navigate('/')}>
        Weiter
      </Button>
    </div>
  );
};
