import { ClientBackendContext } from '@kidsmanager/ui-api';
import {
  Button,
  DialogConfirm,
  DialogContext,
  GhostButton,
  LinkButton,
  Tabs
} from '@kidsmanager/ui-core';
import { IRosterTemplate } from '@kidsmanager/util-models';
import { useContext, useEffect, useState } from 'react';
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';
import { ContextAdminRosterOutlet } from './context-admin-roster-outlet';
import { MasterDetail } from '../components/master-detail';

const tabs = [
  { path: '', title: 'Allgemein' },
  { path: 'rules', title: 'Regeln' },
  { path: 'non-work', title: 'Nichtleistungszeiten' }
];

export const AdminRosters = () => {
  const params = useParams<{ id: string }>();
  const client = useContext(ClientBackendContext);
  const dialog = useContext(DialogContext);
  const navigate = useNavigate();
  const [templates, setTemplates] = useState<IRosterTemplate[]>([]);
  const [selected, setSelected] = useState<IRosterTemplate>();
  const [isDirty, setIsDirty] = useState(false);
  const [area, setArea] = useState('');

  useEffect(() => {
    client.admin.roster.templates.find().then((templates) => {
      templates.sort((a, b) => a.name.localeCompare(b.name));
      setTemplates(templates);
      const editable =
        templates.find((t) => t.id === params.id) || templates[0];
      setSelected(structuredClone(editable));
    });
  }, [client, params.id]);

  const handleAddTemplate = () => {
    client.admin.roster.templates.add().then((template) => {
      if (!template) {
        return;
      }
      setTemplates([...templates, template]);
      setSelected(structuredClone(template));
    });
  };

  const handleSave = () => {
    if (!selected) {
      return;
    }
    client.admin.roster.templates.update(selected).then((updated) => {
      //replace the updated template in the list
      const index = templates.findIndex((t) => t.id === updated.id);
      const updatedTemplates = [...templates];
      updatedTemplates[index] = updated;
      setTemplates(updatedTemplates);
      setSelected(structuredClone(updated));
      setIsDirty(false);
    });
  };

  const handleCancel = () => {
    if (!selected) {
      return;
    }
    const id = selected.id;
    const current = templates.find((t) => t.id === id);
    if (!current) {
      return;
    }
    setSelected(structuredClone(current));
    setIsDirty(false);
  };

  const handleDelete = (id: string) => {
    dialog.open(
      <DialogConfirm
        callToAction="Löschen"
        onCancel={() => dialog.close()}
        onConfirm={() => {
          client.admin.roster.templates.delete(id).then(() => {
            const updatedTemplates = templates.filter((t) => t.id !== id);
            if (updatedTemplates.length > 0) {
              setSelected(structuredClone(updatedTemplates[0]));
            } else {
              setSelected(undefined);
            }
            setTemplates(updatedTemplates);
            dialog.close();
          });
        }}
      >
        <div>&quot;{selected?.name}&quot; Dienstvorlage wirklich löschen?</div>
      </DialogConfirm>
    );
  };

  const handleTemplateSwitch = (
    e: React.MouseEvent<HTMLAnchorElement>,
    id: string,
    area: string
  ) => {
    if (isDirty) {
      e.preventDefault();
      dialog.open(
        <DialogConfirm
          callToAction="Fortfahren"
          onCancel={() => {
            dialog.close();
          }}
          onConfirm={() => {
            dialog.close();
            setIsDirty(false);
            navigate(`/admin/roster/${id}/${area}`);
          }}
        >
          <div>
            <p>Es gibt ungespeicherte Änderungen. </p>
            <p>
              Beim Fortfahren gehen diese Änderungen verloren. Sicher
              fortfahren?
            </p>
          </div>
        </DialogConfirm>
      );
    }
  };

  return (
    <MasterDetail
      widths={{ master: 300 }}
      master={
        <div className="px-4 py-6">
          <h1 className="mb-2">Dienstvorlagen</h1>
          <ul className="border-silver-200 min-h-64 border py-2">
            {templates.map((template) => (
              <li
                key={template.id}
                style={{
                  backgroundColor:
                    selected?.id === template.id ? 'var(--color-accent)' : '',
                  color: selected?.id === template.id ? 'white' : ''
                }}
              >
                <Link
                  className="relative block w-full px-3 leading-10"
                  to={`/admin/roster/${template.id}/${area}`}
                  onClick={(e) => handleTemplateSwitch(e, template.id, area)}
                >
                  {template.name}
                  {selected?.id === template.id && (
                    <button
                      onClick={handleDelete.bind(this, selected.id)}
                      className="material-icons absolute right-1 top-1/2 flex h-8 w-8 -translate-y-1/2 items-center rounded-full hover:bg-white/20"
                    >
                      close
                    </button>
                  )}
                </Link>
              </li>
            ))}
          </ul>
          <div className="my-1 text-right">
            <LinkButton onClick={handleAddTemplate.bind(this)}>
              + hinzuf&uuml;gen
            </LinkButton>
          </div>
        </div>
      }
      detail={
        <Tabs
          basePath={`/admin/roster/${selected?.id}`}
          tabs={tabs}
          onChange={setArea.bind(this)}
        >
          <Outlet
            context={
              {
                template: selected,
                onChange: setIsDirty.bind(this, true)
              } as ContextAdminRosterOutlet
            }
          />
          <div className="my-6 flex gap-2">
            <GhostButton
              type="reset"
              disabled={!isDirty}
              onClick={handleCancel.bind(this)}
            >
              Abbrechen
            </GhostButton>
            <Button
              type="submit"
              disabled={!isDirty}
              onClick={handleSave.bind(this)}
            >
              Speichern
            </Button>
          </div>
        </Tabs>
      }
    />
  );
};
