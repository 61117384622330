import { useState } from 'react';
import './range.css';

export interface DualRangeProps {
  from: number;
  to: number;
  min: number;
  max: number;
  onChange?: (from: number, to: number) => void;
}

export const DualRange = (props: DualRangeProps) => {
  const [from, setFrom] = useState(props.from || props.min);
  const [to, setTo] = useState(props.to || props.max);

  const handleMinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(event.target.value);
    if (value < to) {
      setFrom(value);
      props.onChange?.(value, to);
    }
  };

  const handleMaxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(event.target.value);
    if (value > from) {
      setTo(value);
      props.onChange?.(from, value);
    }
  };

  return (
    <div className="flex items-center gap-2">
      <span className="text-sm">{from}</span>
      <div className="relative max-h-12 flex-1">
        <input
          className="dual"
          style={{ height: 0, zIndex: 1 }}
          type="range"
          value={from}
          min={props.min}
          max={props.max}
          onChange={handleMinChange.bind(this)}
        />
        <input
          className="dual"
          type="range"
          value={to}
          min={props.min}
          max={props.max}
          onChange={handleMaxChange.bind(this)}
        />
      </div>
      <span className="text-sm">{to}</span>
    </div>
  );
};
