export interface UnpackResult {
  start: string;
  end: string;
  hrs: number;
}
export const unpackHours = (
  startStr: string | null | FormDataEntryValue,
  hoursStr: string | null | FormDataEntryValue
): UnpackResult => {
  const start = startStr as string;
  const hrs = parseFloat((hoursStr as string) ?? '0');
  const end = endFromStartAndHours(start, hrs);
  return { start, end, hrs };
};

export const endFromStartAndHours = (start: string, hours: number): string => {
  const [h, m] = start.split(':').map((x) => parseInt(x));
  const x = new Date(new Date().setHours(h, m) + hours * 60 * 60 * 1000);
  return `${x.getHours().toString().padStart(2, '0')}:${x.getMinutes().toString().padStart(2, '0')}`;
};
