import { LinkButton } from '@kidsmanager/ui-core';
import { useEffect, useRef, useState } from 'react';

export interface RosterBlockPopupProps {
  notes?: string[];
  onIgnore?: () => void;
  onClose?: () => void;
}

export const RosterBlockPopup = (props: RosterBlockPopupProps) => {
  const [selected, setSelected] = useState(0);
  const [notes] = useState(props.notes || []);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (ref.current && !ref.current.parentNode?.contains(e.target as Node)) {
        props.onClose?.();
      }
    };
    setTimeout(() => {
      window.addEventListener('click', handleClick);
    }, 100);
    return () => window.removeEventListener('click', handleClick);
  }, [props]);

  const handleBack = () => {
    if (selected > 0) {
      setSelected(selected - 1);
    }
  };
  const handleForward = () => {
    if (selected < notes.length - 1) {
      setSelected(selected + 1);
    }
  };

  return (
    <div
      ref={ref}
      className="shadow-hdr w-screen max-w-xs bg-white p-6"
      onClick={(e) => e.stopPropagation()}
    >
      <h2 className="flex items-center text-sm font-bold">
        <span className="flex-1">
          {notes.length > 1
            ? `${selected + 1} von ${notes.length} Gründen`
            : 'Grund'}
        </span>
        <span>
          <span
            className="material-icons w-8 text-center"
            onClick={handleBack.bind(this)}
            style={{
              opacity: selected === 0 ? '0.2' : '1',
              cursor: selected === 0 ? 'not-allowed' : 'pointer'
            }}
          >
            chevron_left
          </span>
          <span
            className="material-icons w-8 text-center"
            onClick={handleForward.bind(this)}
            style={{
              opacity: selected === notes.length - 1 ? '0.2' : '1',
              cursor: selected === notes.length - 1 ? 'not-allowed' : 'pointer'
            }}
          >
            chevron_right
          </span>
        </span>
      </h2>
      <p className="min-h-28 py-2">{notes[selected]}</p>
      <div className="text-right">
        <LinkButton onClick={props.onIgnore?.bind(this)}>
          Ignorieren, trotzdem hinzufügen
        </LinkButton>
      </div>
    </div>
  );
};
