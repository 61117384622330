import { Outlet } from 'react-router-dom';

export const FeatureMfa = () => {
  return (
    <div>
      <div className="bg-silver-25 fixed inset-x-0 top-0 flex min-h-[29px] items-center justify-end">
        <a
          className="px-2 text-sm text-black/50 hover:text-black/80 hover:underline"
          href="/logout"
        >
          Abbrechern
        </a>
      </div>
      <div className="mt-24 text-center">
        <h2 className="my-5 text-4xl">Zwei-Faktor-Authentifizierung</h2>
        <Outlet />
      </div>
    </div>
  );
};
