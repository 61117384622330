import { toLocaleISODate } from '@kidsmanager/util-common';
import {
  DayData,
  RulesEngineResult,
  WorkHistory,
  RuleBase
} from '../rule-base';
import { longestWorkStreak } from './_helpers';

export class RuleMinRestInWeek extends RuleBase {
  /**
   * Weekly rest period of 2 consecutive days
   */
  test(from: Date, until: Date, history?: WorkHistory): RulesEngineResult {
    if (!history) {
      return { status: 'ok' };
    }

    const days = [...history.previous, ...history.current, ...history.next];
    if (days.length === 0) {
      return { status: 'ok' };
    }

    const range = getStartEndIndices(from, until, days);
    if (!range) {
      return { status: 'ok' };
    }

    if (range.start < 7 || range.start >= 14) {
      console.warn('entry not starting in the current week', range.start);
    }
    if (range.end - range.start > 1) {
      return {
        status: 'warn',
        message: 'Eintrag länger als 2 Tage nicht analysiert'
      };
    }

    //convert to 1 if worked, 0 if not worked
    const worked = days.map((d) => (d.entries.length > 0 ? 1 : 0));
    for (let i = range.start; i <= range.end; i++) {
      worked[i] = 1;
    }

    const longestStreak = longestWorkStreak(worked);
    if (longestStreak > 5) {
      return {
        status: 'warn',
        message: 'Eine 2-tägige Pause ist erforderlich'
      };
    }

    return { status: 'ok' };
  }
}

const getStartEndIndices = (from: Date, until: Date, days: DayData[]) => {
  const dStart = toLocaleISODate(from);
  const dEnd = toLocaleISODate(until);
  const start = days.findIndex(({ date }) => toLocaleISODate(date) === dStart);
  const end = days.findIndex(({ date }) => toLocaleISODate(date) === dEnd);

  if (start === -1) {
    console.warn('day not found in history', dStart);
    return undefined;
  }
  if (end === -1) {
    console.warn('day not found in history', dEnd);
    return undefined;
  }
  return { start, end };
};
