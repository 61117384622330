/* eslint-disable @typescript-eslint/no-unused-vars */

export const InMins24Hrs = 24 * 60;

export interface DayEntry {
  startSeconds: number;
  durationSeconds: number;
  breakDurationSeconds: number;
  infoOnly: boolean;
  start(): Date;
  end(): Date;
}

export interface DayData {
  date: Date;
  entries: DayEntry[];
}

export interface WorkHistory {
  previous: DayData[];
  current: DayData[];
  next: DayData[];
}

export type RulesFunc = (
  from: Date,
  until: Date,
  history?: WorkHistory
) => RulesEngineResult;

export interface RulesEngineResult {
  status: 'ok' | 'warn' | 'fail';
  message?: string;
}

export type RuleCategory = 'daily' | 'weekly' | 'other' | 'rest';

export interface RuleSpec {
  id: string;
  description: string;
  category: RuleCategory;
  defaults: {
    key: string;
    type: 'number' | 'time' | 'day';
    value: string;
  }[];
}

export interface ShiftScope {
  hours: number;
  mins: number;
  duration: number;
}
export interface Assignment {
  hours: number; // start hours in local time
  mins: number; // start minutes in local time
  totalMins: number; // start offset in minutes from midnight
  duration: number; // duration in minutes
  readonly: boolean;
}

export interface BlockStatus {
  blocked: boolean;
  notes: string[];
}

export abstract class RuleBase {
  abstract test(
    from: Date,
    until: Date,
    history?: WorkHistory
  ): RulesEngineResult;

  specs(): RuleSpec[] {
    return [];
  }

  check(
    test: ShiftScope,
    days: Date[],
    plan: Assignment[][],
    blocked: BlockStatus[]
  ): void {
    // do nothing by default
  }

  specVal(
    key: string,
    type: 'number' | 'time',
    value: string | number
  ): { key: string; type: 'number' | 'time'; value: string } {
    return { key, type, value: value.toString() };
  }

  setBlocked(blocked: BlockStatus[], index: number, note: string): void {
    if (index >= 0 && index < blocked.length) {
      blocked[index].blocked = true;
      if (!blocked[index].notes) {
        blocked[index].notes = [];
      }
      if (!blocked[index].notes.includes(note)) {
        blocked[index].notes.push(note);
      }
    }
  }
}

export const TicksPerHour = 60 * 60 * 1000;
