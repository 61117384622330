import { RulesEngineResult, TicksPerHour, RuleBase } from '../rule-base';

export class RuleMinHours extends RuleBase {
  test(from: Date, until: Date): RulesEngineResult {
    const hours = (until.getTime() - from.getTime()) / TicksPerHour;
    if (hours < 0) {
      return {
        status: 'fail',
        message: 'Ende vor Beginn ist nicht erlaubt'
      };
    }
    return { status: 'ok' };
  }
}
