import {
  Assignment,
  BlockStatus,
  RuleBase,
  RulesEngineResult,
  ShiftScope
} from '../rule-base';
import { hasOverlap, planToMask, shiftByDays, testToMask } from './_helpers';

export class RuleNoOverlap extends RuleBase {
  test(from: Date, until: Date): RulesEngineResult {
    console.log('RuleNoOverlap.test', from, until);
    return { status: 'ok' };
  }

  check(
    test: ShiftScope,
    _days: Date[],
    plan: Assignment[][],
    blocked: BlockStatus[]
  ): void {
    const mask = planToMask(plan);
    const required = testToMask(mask.length, test);

    for (const [day, shifts] of plan.entries()) {
      if (shifts.length === 0 && hasOverlap(mask, required)) {
        blocked[day].blocked = true;
        blocked[day].notes.push(
          'Der ausgewählte Dienst würde sich mit dem bestehenden Plan überschneiden.'
        );
      }
      shiftByDays(required, 1);
    }
  }
}
