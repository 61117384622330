import {
  Button,
  ButtonGroup,
  DialogContext,
  Info,
  ToggleButton
} from '@kidsmanager/ui-core';
import { useContext, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { OutletContextAdminUser } from './outlet-context-admin-user';
import { AdminUserBase } from './admin-user-base';
import { DialogNewPassword } from './dialogs/dialog-new-password';
import { ClientBackendContext } from '@kidsmanager/ui-api';

export const AdminUserActions = () => {
  const context = useOutletContext<OutletContextAdminUser>();
  const dialog = useContext(DialogContext);
  const client = useContext(ClientBackendContext);
  const { selectedUser } = context;
  const [inactive, setInactive] = useState(selectedUser.inactive);
  const [lockMessage, setLockMessage] = useState<string>();

  useEffect(() => {
    setInactive(selectedUser.inactive);
    setLockMessage(
      selectedUser.roles?.includes('admin')
        ? 'Admins können nicht gesperrt werden'
        : undefined
    );
  }, [selectedUser]);

  const handleStatusChange = (inactive: boolean) => {
    setInactive(inactive);
    context.onChange({ ...selectedUser, inactive });
  };

  const handleNewPassword = () => {
    dialog.open(
      <DialogNewPassword
        user={selectedUser}
        onCancel={() => dialog.close()}
        onSave={(password) => {
          dialog.close();
          client.admin.users.resetPassword(selectedUser.userId, password);
        }}
      />
    );
  };

  return (
    <AdminUserBase
      modules={context.modules}
      selectedUser={context.selectedUser}
    >
      <div className="mt-8 grid max-w-sm grid-cols-[120px_auto] items-center gap-y-4">
        <label className="text-sm">Zugang</label>
        <div className="flex items-center gap-1">
          <ButtonGroup>
            <ToggleButton
              active={!inactive}
              onChange={handleStatusChange.bind(this, false)}
            >
              Aktiv
            </ToggleButton>
            <ToggleButton
              active={inactive}
              disabled={lockMessage !== undefined}
              onChange={handleStatusChange.bind(this, true)}
            >
              Gesperrt
            </ToggleButton>
          </ButtonGroup>
          {lockMessage && <Info position="center">{lockMessage}</Info>}
        </div>
        <label className="text-sm">Passwort</label>
        <Button onClick={handleNewPassword.bind(this)}>
          neues Passwort erstellen
        </Button>
      </div>
    </AdminUserBase>
  );
};
