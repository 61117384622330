import {
  QRBitBuffer,
  QRCodeLengthLimit,
  QRErrorCorrectLevel,
  QRMode,
  QRPolynomial,
  QRUtil,
  ReedSolomon
} from './qr-code-core';

export class QRCodeGenerator {
  generateModules(
    url: string,
    errorCorrectionLevel: 'L' | 'M' | 'Q' | 'H' = 'L'
  ): boolean[][] {
    const ecl = this.determineEcl(errorCorrectionLevel);
    const type = this.determineQrType(url, ecl);
    const data = QRCodeGenerator.createData(type, ecl, url);

    const bestMask = this.determineBestMask(data, type, ecl);
    return this.generate(data, type, ecl, bestMask);
  }

  private generate(
    data: number[],
    type: number,
    ecl: number,
    maskPattern: number,
    test = false
  ) {
    const dimension = type * 4 + 17;
    const modules: boolean[][] = Array.from({ length: dimension }, () =>
      new Array(dimension).fill(null)
    );

    this.addPositionMarker(modules, 0, 0);
    this.addPositionMarker(modules, dimension - 7, 0);
    this.addPositionMarker(modules, 0, dimension - 7);
    this.addPositionAdjustmentPattern(modules, type);
    this.addTimingPattern(modules);
    this.addTypeInfo(modules, ecl, maskPattern, test);
    this.addTypeNumber(modules, type, test);
    this.mapData(modules, data, maskPattern);
    return modules;
  }

  private determineQrType(content: string, ecl: number) {
    const length =
      encodeURI(content).replace(/%[0-9a-fA-F]{2}/g, 'a').length + 3;
    return QRCodeLengthLimit[ecl].findIndex((value) => length < value) + 1;
  }

  private determineEcl(errorCorrection: 'L' | 'M' | 'Q' | 'H') {
    return QRErrorCorrectLevel[errorCorrection];
  }

  private determineBestMask(data: number[], type: number, ecl: number) {
    return Array.from({ length: 8 })
      .map((_, mask) => {
        const interim = this.generate(data, type, ecl, mask, true);
        return { mask, lp: QRUtil.getLostPoint(interim) };
      })
      .reduce((min, val) => (min.lp < val.lp ? min : val)).mask;
  }

  private addPositionMarker(modules: boolean[][], row: number, col: number) {
    for (let r = -1; r <= 7; r++) {
      if (row + r <= -1 || modules.length <= row + r) continue;

      for (let c = -1; c <= 7; c++) {
        if (col + c <= -1 || modules.length <= col + c) continue;

        modules[row + r][col + c] =
          (0 <= r && r <= 6 && (c === 0 || c === 6)) ||
          (0 <= c && c <= 6 && (r === 0 || r === 6)) ||
          (2 <= r && r <= 4 && 2 <= c && c <= 4);
      }
    }
  }

  private addTimingPattern(modules: boolean[][]) {
    for (let r = 8; r < modules.length - 8; r++) {
      if (modules[r][6] !== null) continue;
      modules[r][6] = r % 2 === 0;
    }

    for (let c = 8; c < modules.length - 8; c++) {
      if (modules[6][c] !== null) continue;
      modules[6][c] = c % 2 === 0;
    }
  }

  private addPositionAdjustmentPattern(modules: boolean[][], type: number) {
    const pos = QRUtil.getPatternPosition(type);
    pos.forEach((row) => {
      pos.forEach((col) => {
        if (modules[row][col] !== null) return;

        for (let r = -2; r <= 2; r++) {
          for (let c = -2; c <= 2; c++) {
            modules[row + r][col + c] =
              r === -2 ||
              r === 2 ||
              c === -2 ||
              c === 2 ||
              (r === 0 && c === 0);
          }
        }
      });
    });
  }

  private addTypeNumber(modules: boolean[][], type: number, test: boolean) {
    if (type < 7) return;

    const bits = QRUtil.getBCHTypeNumber(type);

    for (let i = 0; i < 18; i++) {
      const mod = !test && ((bits >> i) & 1) === 1;
      modules[Math.floor(i / 3)][(i % 3) + modules.length - 8 - 3] = mod;
      modules[(i % 3) + modules.length - 8 - 3][Math.floor(i / 3)] = mod;
    }
  }

  private addTypeInfo(
    modules: boolean[][],
    ecl: number,
    maskPattern: number,
    test: boolean
  ) {
    const data = (ecl << 3) | maskPattern;
    const bits = QRUtil.getBCHTypeInfo(data);

    for (let i = 0; i < 15; i++) {
      const mod = !test && ((bits >> i) & 1) === 1;

      if (i < 6) modules[i][8] = mod;
      else if (i < 8) modules[i + 1][8] = mod;
      else modules[modules.length - 15 + i][8] = mod;
    }

    for (let i = 0; i < 15; i++) {
      const mod = !test && ((bits >> i) & 1) === 1;

      if (i < 8) modules[8][modules.length - i - 1] = mod;
      else if (i < 9) modules[8][15 - i - 1 + 1] = mod;
      else modules[8][15 - i - 1] = mod;
    }

    modules[modules.length - 8][8] = !test;
  }

  private mapData(modules: boolean[][], data: number[], maskPattern: number) {
    let inc = -1;
    let row = modules.length - 1;
    let bitIndex = 7;
    let byteIndex = 0;

    for (let col = modules.length - 1; col > 0; col -= 2) {
      if (col === 6) col--;

      // eslint-disable-next-line no-constant-condition
      while (true) {
        for (let c = 0; c < 2; c++) {
          if (modules[row][col - c] === null) {
            let dark =
              byteIndex < data.length
                ? ((data[byteIndex] >>> bitIndex) & 1) === 1
                : false;
            if (QRUtil.getMask(maskPattern, row, col - c)) dark = !dark;
            modules[row][col - c] = dark;
            bitIndex--;
            if (bitIndex === -1) {
              byteIndex++;
              bitIndex = 7;
            }
          }
        }

        row += inc;
        if (row < 0 || modules.length <= row) {
          row -= inc;
          inc = -inc;
          break;
        }
      }
    }
  }

  private static createData(type: number, ecl: number, url: string): number[] {
    const blocks = ReedSolomon.blocks(type, ecl);
    const totalBits = blocks.reduce((sum, b) => sum + b.dataCount, 0) * 8;
    const data = Array.from(new TextEncoder().encode(url)); //UTF-8 encoded

    const buffer = new QRBitBuffer();
    buffer.put(QRMode.MODE_8BIT_BYTE, 4);
    buffer.put(data.length, QRUtil.getNumBits(QRMode.MODE_8BIT_BYTE, type));
    buffer.append(data);
    buffer.pad(totalBits);
    return QRCodeGenerator.createBytes(buffer, blocks);
  }

  private static createBytes(
    buffer: QRBitBuffer,
    blocks: { totalCount: number; dataCount: number }[]
  ): number[] {
    let offset = 0;
    let maxDcCount = 0;
    let maxEcCount = 0;
    const dcdata: number[][] = new Array(blocks.length);
    const ecdata: number[][] = new Array(blocks.length);
    for (let r = 0; r < blocks.length; r++) {
      const dcCount = blocks[r].dataCount;
      const ecCount = blocks[r].totalCount - dcCount;
      maxDcCount = Math.max(maxDcCount, dcCount);
      maxEcCount = Math.max(maxEcCount, ecCount);
      dcdata[r] = new Array(dcCount);
      for (let i = 0; i < dcdata[r].length; i++) {
        dcdata[r][i] = 0xff & buffer.buffer[i + offset];
      }
      offset += dcCount;
      const rsPoly = QRUtil.getErrorCorrectPolynomial(ecCount);
      const rawPoly = new QRPolynomial(dcdata[r], rsPoly.length() - 1);
      const modPoly = rawPoly.mod(rsPoly);
      ecdata[r] = new Array(rsPoly.length() - 1);
      for (let i = 0; i < ecdata[r].length; i++) {
        const modIndex = i + modPoly.length() - ecdata[r].length;
        ecdata[r][i] = modIndex >= 0 ? modPoly.get(modIndex) : 0;
      }
    }
    let totalCodeCount = 0;
    for (let i = 0; i < blocks.length; i++) {
      totalCodeCount += blocks[i].totalCount;
    }
    const data: number[] = new Array(totalCodeCount);
    let index = 0;
    for (let i = 0; i < maxDcCount; i++) {
      for (let r = 0; r < blocks.length; r++) {
        if (i < dcdata[r].length) {
          data[index++] = dcdata[r][i];
        }
      }
    }
    for (let i = 0; i < maxEcCount; i++) {
      for (let r = 0; r < blocks.length; r++) {
        if (i < ecdata[r].length) {
          data[index++] = ecdata[r][i];
        }
      }
    }
    return data;
  }
}
