import { useContext, useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { ClientBackendContext } from '@kidsmanager/ui-api';
import { Header } from '@kidsmanager/ui-core';
import { Role } from '@kidsmanager/util-storage';
import { IAccessMap } from '@kidsmanager/util-models';

export interface AppGuardProps {
  requires?: Role;
}

export const AppGuard = ({ requires }: AppGuardProps) => {
  const client = useContext(ClientBackendContext);
  const params = useParams();
  const navigate = useNavigate();

  const [accessMap, setAccessMap] = useState<IAccessMap>();
  const [groupId, setGroupId] = useState<string | undefined>(params.groupId);

  useEffect(() => {
    setGroupId(params.groupId);
  }, [params]);

  useEffect(() => {
    client.settings.accessMap().then((map) => {
      if (requires && !client.auth.inRole(requires)) {
        console.warn(`'${requires}' permission missing for route`);
        navigate('/');
      } else if (groupId && !map.groups.find((g) => g.id === groupId)) {
        console.warn(`User has no access to group '${groupId}'`);
        navigate('/');
      } else {
        setAccessMap(map);
      }
    });
  }, [client, navigate, requires, groupId]);

  return (
    accessMap && (
      <>
        <Header {...accessMap} />
        <Outlet />
      </>
    )
  );
};
