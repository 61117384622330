import { Dialog, DialogContext, LinkButton } from '@kidsmanager/ui-core';
import { useContext, useEffect } from 'react';
import { ShiftSpec } from './components/shift-spec';
import { ShiftEditor } from './components/shift-editor';
import { SelectableShiftSpec } from '../roster-models';
import { ClientBackendContext } from '@kidsmanager/ui-api';
import { IShiftSpecUpdate } from '@kidsmanager/util-models';

export interface ShiftManagerProps {
  group: string;
  year: number;
  month: number;
  specs: SelectableShiftSpec[];
  onSelected?: (spec: SelectableShiftSpec) => void;
  onToggled?: (spec: SelectableShiftSpec, visible: boolean) => void;
  onChange?: (value: IShiftSpecUpdate) => void;
}

export const ShiftManager = (props: ShiftManagerProps) => {
  const dialog = useContext(DialogContext);
  const client = useContext(ClientBackendContext);

  useEffect(() => {
    if (!props.specs.find((x) => x.selected) && props.specs.length > 3) {
      props.specs[2].selected = true;
    }
  }, [props.specs]);

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if ((e.target as HTMLElement).closest('[role="dialog"]')) {
        return;
      }
      e.preventDefault();
      if (e.code === 'Space') {
        if (e.shiftKey) {
          const index = props.specs.findIndex((x) => x.selected);
          const next = index >= props.specs.length - 1 ? 2 : index + 1;
          props.onSelected?.(props.specs[next]);
        } else {
          //specs starting with the same letter symbol, but not currently selected
          const current = props.specs
            .find((x) => x.selected)
            ?.symbol?.toLowerCase();
          if (!current) return;
          const next = props.specs.find(
            (x) => x.symbol?.toLowerCase().at(0) === current[0] && !x.selected
          );
          if (next) {
            props.onSelected?.(next);
          }
        }
      }
    };
    document.addEventListener('keypress', handleKeyPress);
    return () => {
      document.removeEventListener('keypress', handleKeyPress);
    };
  }, [props]);

  const handleSave = async (specs: SelectableShiftSpec[]) => {
    const update = await client.roster.shifts.update(
      props.group,
      props.year,
      props.month,
      specs
    );
    props.onChange?.(update);
    dialog.close();
  };

  const handleEdit = () => {
    dialog.open(
      <Dialog
        title="Dienste"
        width="w-[650px]"
        close={dialog.close.bind(dialog)}
      >
        <ShiftEditor
          group={props.group}
          specs={props.specs}
          onCancel={() => dialog.close()}
          onSave={handleSave.bind(this)}
        />
      </Dialog>
    );
  };

  return (
    <div className="relative mt-6 min-h-[450px] w-full rounded-md bg-neutral-100 py-1">
      <ul className="flex-col space-y-2">
        {props.specs.map((spec) => (
          <li key={spec.index}>
            <ShiftSpec
              {...spec}
              onSelected={props.onSelected?.bind(this, spec)}
              onToggle={props.onToggled?.bind(this, spec)}
            />
          </li>
        ))}
      </ul>
      <div className="absolute bottom-0 right-0 p-2">
        <LinkButton onClick={handleEdit.bind(this)}>&Auml;ndern</LinkButton>
      </div>
    </div>
  );
};
