import { useMemo } from 'react';
import { QRCodeGenerator } from './qr-code-gen';

export interface QrCodeProps {
  url: string;
}

export const QrCode = ({ url }: QrCodeProps) => {
  const modules = useMemo(
    () => new QRCodeGenerator().generateModules(url),
    [url]
  );

  return modules ? (
    <div className="inline-block bg-white p-4">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width={modules.length * 4}
        height={modules.length * 4}
      >
        {modules.map((row, y) =>
          row.map(
            (cell, x) =>
              cell && (
                <rect
                  key={`${x}-${y}`}
                  x={x * 4}
                  y={y * 4}
                  width="4"
                  height="4"
                  fill="black"
                />
              )
          )
        )}
      </svg>
    </div>
  ) : null;
};
