import { ClientBackendContext } from '@kidsmanager/ui-api';
import { Button, Checkbox, Input, Progress } from '@kidsmanager/ui-core';
import { IGoogleUser } from '@kidsmanager/util-models';
import { useContext, useMemo, useState } from 'react';
import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import { OutletContextAdminUser } from './outlet-context-admin-user';

interface ElevateEvent {
  data?: {
    success: boolean;
    token: string;
    refreshToken: string;
  };
}

const createUserName = (user: IGoogleUser, existing: string[]) => {
  const concatWithCount = (base: string, count: number) =>
    count === 0 ? base : `${base}${count}`;
  const base = `${user.firstName.at(0) || 'x'}.${user.lastName}`.toLowerCase();
  let count = 0;
  while (existing.includes(concatWithCount(base, count))) {
    count++;
  }
  return concatWithCount(base, count);
};

export const AdminUserLink = () => {
  const [available, setAvailable] = useState<IGoogleUser[]>([]);
  const [pattern, setPattern] = useState('');
  const [selected, setSelected] = useState<IGoogleUser[]>([]);
  const [loading, setLoading] = useState(false);
  const [debounceTimer, setDebounceTimer] = useState<NodeJS.Timeout | null>(
    null
  );

  const client = useContext(ClientBackendContext);
  const context = useOutletContext<OutletContextAdminUser>();
  const navigate = useNavigate();

  const missingPermissions = useMemo(() => {
    return client.auth.requiresSsoElevation();
  }, [client]);

  const handleGoogleElevationMessage = (event?: ElevateEvent) => {
    window.removeEventListener('message', handleGoogleElevationMessage);
    if (event?.data?.success) {
      client.auth.updateTokens(event.data.token, event.data.refreshToken);
      window.location.reload();
    }
  };

  const handleSsoElevation = () => {
    const tenant = localStorage.getItem('sso-tenant');
    const origin = window.location.origin;
    client.apiOauth2
      .googleWorkspaceUrl('ADMIN', tenant, origin, client.auth.email())
      .then((url: { url: string }) => {
        const width = 600;
        const height = 700;
        const left = window.screen.width / 2 - width / 2;
        const top = window.screen.height / 2 - height / 2;
        const windowProps = `toolbar=no, menubar=no, width=${width}, height=${height}, top=${top}, left=${left}`;
        window.addEventListener('message', handleGoogleElevationMessage);
        window.open(url.url, '', windowProps);
      });
  };

  const handleUserChecked = (user: IGoogleUser, checked: boolean) => {
    setSelected((prev) =>
      checked ? [...prev, user] : prev.filter((g) => g.email !== user.email)
    );
  };

  const handleAddUsers = () => {
    selected.forEach((user) => {
      const locked = context.lockedUsers.find((u) => u.email === user.email);
      const existing = [
        ...context.activeUsers.map((x) => x.username || ''),
        ...context.lockedUsers.map((x) => x.username || '')
      ];
      if (locked) {
        locked.inactive = false;
        context.onChange(locked);
      } else {
        context.onChange({
          userId: 'new',
          username: createUserName(user, existing),
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          verifiedEmail: true
        });
      }
    });

    navigate('/admin/users');
  };

  const handleSearch = (value: string) => {
    setPattern(value);
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }
    if (value.trim().length === 0) {
      setAvailable([]);
      return;
    }
    setDebounceTimer(
      setTimeout(() => {
        const active = context.activeUsers.map((u) => u.email || '');
        client.admin.users.google(value).then((users) => {
          setAvailable(users.filter((u) => !active.includes(u.email)));
          setLoading(false);
        });
      }, 500)
    );
    setLoading(true);
  };

  return (
    <div className="relative">
      <h1 className="mb-4 pt-4 text-2xl font-semibold">
        Aus Google Workspace hinzufügen...
      </h1>
      <Link
        to="/admin/users"
        className="absolute right-0 top-0 -mt-4 text-4xl text-gray-600 hover:text-black"
        aria-label="Close"
      >
        &times;
      </Link>
      {missingPermissions ? (
        <div className="max-w-md">
          <p>
            Um weitere BenutzerInnen zu KidsManager hinzuzufügen, brauchen wir
            ein paar zusätzliche Berechtigungen für dein Google Workspace-Konto.
          </p>
          <p className="mt-4">Dies ist eine einmalige Aktion.</p>
          <Button onClick={handleSsoElevation.bind(this)} className="mt-8">
            Zugriff gewähren
          </Button>
        </div>
      ) : (
        <>
          <div className="max-w-md">
            <Input
              onChange={handleSearch.bind(this)}
              mask="names"
              value={pattern}
            />
            <div className="min-h-2">
              {loading && <Progress mode="indeterminate" />}
            </div>
            <div className="border-silver-200 max-h-96 min-h-44 overflow-y-auto rounded border bg-white p-4 pt-3">
              {available.length > 0 ? (
                available.map((user) => (
                  <div className="py-1" key={user.email}>
                    <Checkbox
                      checked={selected.includes(user)}
                      onChange={(checked) => handleUserChecked(user, checked)}
                    >
                      {user.firstName} {user.lastName}
                    </Checkbox>
                  </div>
                ))
              ) : (
                <span className="text-sm text-black/30">
                  {loading
                    ? 'Loading...'
                    : 'Keine weiteren Nutzer in Google Workspace gefunden.'}
                </span>
              )}
            </div>
          </div>

          <div className="mt-4">
            <Button
              onClick={handleAddUsers.bind(this)}
              disabled={selected.length === 0}
            >
              hinzufügen
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
