import * as Account from '@kidsmanager/feature-account';
import * as Admin from '@kidsmanager/feature-admin';
import { JournalDashboard } from '@kidsmanager/feature-journal';
import { Roster } from '@kidsmanager/feature-roster';
import { TeamDashboard } from '@kidsmanager/feature-team';
import { Route } from 'react-router-dom';

import { AppGuard } from './app-guard';
import { PageNotFound } from './page-not-found';

export const AppRoutes = (): React.ReactNode => {
  return (
    <>
      <Route path="/login" element={<Account.FeatureLogin brand="kidsmgr" linkWithLegacy />} />
      <Route path="/login/mfa" element={<Account.FeatureMfa />}>
        <Route path="select" element={<Account.FeatureMfaSelect linkWithLegacy />} />
        <Route path="email" element={<Account.FeatureMfaEmail linkWithLegacy />} />
        <Route path="authenticator" element={<Account.FeatureMfaAuthenticator linkWithLegacy />} />
      </Route>
      <Route path="/login/legacy" element={<Account.LegacyRedirect />} />
      <Route path="/logout" element={<Account.FeatureLogout unlinkWithLegacy />} />
      <Route path="/google-callback" element={<Account.FeatureGoogleCallback />} />
      <Route path="/verify-email" element={<Account.FeatureEmailConfirmed />} />
      <Route element={<AppGuard />}>
        <Route path="/" element={<JournalDashboard />} />
        <Route path="/settings/credentials" element={<Account.FeatureCredentials />} />
        <Route path="/team/:group" element={<TeamDashboard />}>
          <Route path="/team/:group/roster/:month?" element={<Roster />} />
        </Route>
      </Route>
      <Route path="/admin" element={<AppGuard requires="admin" />}>
        <Route path="users/:id?" element={<Admin.AdminUsers />}>
          <Route index path="profile" element={<Admin.AdminUserProfile />} />
          <Route path="permissions" element={<Admin.AdminUserPermissions />} />
          <Route path="shifts" element={<Admin.AdminUserShifts />} />
          <Route path="actions" element={<Admin.AdminUserActions />} />
          <Route path="link/:idp" element={<Admin.AdminUserLink />} />
        </Route>
        <Route path="roster/:id?" element={<Admin.AdminRosters />}>
          <Route path="" element={<Admin.AdminRosterGeneral />} />
          <Route path="rules" element={<Admin.AdminRosterRules />} />
          <Route path="non-work" element={<Admin.AdminRosterNonWork />} />
        </Route>
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </>
  );
};
