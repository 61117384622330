import { useMemo } from 'react';

export interface RosterStatusDayProps {
  cover?: 'minimal' | 'full';
  asColumn?: boolean;
}

export const RosterStatusDay = (props: RosterStatusDayProps) => {
  const backgroundColor = useMemo(() => {
    if (props.cover === 'full') {
      return '#6BC131';
    }
    if (props.cover === 'minimal') {
      return '#FFB628';
    }
    return '';
  }, [props.cover]);
  return props.asColumn ? (
    <div className="h-2" style={{ backgroundColor }}></div>
  ) : (
    <div className="h-7 w-2" style={{ backgroundColor }}></div>
  );
};
