import {
  ChangeEvent,
  KeyboardEvent,
  FocusEvent,
  useEffect,
  useState
} from 'react';

export interface InputCodeProps {
  length: number;
  onChange?: (code: string) => void;
  mismatch?: boolean;
}
export const InputCode = (props: InputCodeProps) => {
  const [code, setCode] = useState<string[]>(Array(props.length).fill(''));

  useEffect(() => {
    (document.querySelector('input') as HTMLInputElement)?.focus();
  }, []);

  useEffect(() => {
    const handlePaste = (event: ClipboardEvent) => {
      event.preventDefault();
      const text = event.clipboardData?.getData('text').replace(/\D/g, '');
      if (text) {
        const code = text.split('').slice(0, props.length);
        setCode(code.concat(Array(props.length - code.length).fill('')));
        props.onChange?.(code.join(''));
      }
    };
    window.addEventListener('paste', handlePaste);
    return () => window.removeEventListener('paste', handlePaste);
  }, [props]);

  const onCodeChanged = (
    index: number,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const target = event.target as HTMLInputElement;
    setCode((prev) => {
      const next = [...prev];
      next[index] = target.value;
      return next;
    });
  };

  const clearAndFocusFirst = (target: HTMLInputElement) => {
    setCode(Array(props.length).fill(''));
    (document.querySelector('input') as HTMLInputElement)?.focus();
    props.onChange?.('');
  };

  const onKeyUp = (index: number, event: KeyboardEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    if (event.key === 'Escape') {
      clearAndFocusFirst(target);
      return;
    }

    if (target.value.length === 1) {
      const pos = index + 1 < props.length / 2 ? index + 1 : index + 2;
      const nextInput = target.parentNode?.childNodes[pos] as HTMLInputElement;
      if (nextInput) {
        nextInput.focus();
        nextInput.select();
      }
    } else if (event.key === 'Backspace') {
      const pos = index <= props.length / 2 ? index - 1 : index;
      const prevInput = target.parentNode?.childNodes[pos] as HTMLInputElement;
      if (prevInput) {
        prevInput.focus();
        prevInput.select();
      }
    }
    props.onChange?.(code.join(''));
  };

  const onBlur = (last: boolean, event: FocusEvent<HTMLInputElement>) => {
    if (!last || !props.mismatch || event.target.value.length === 0) {
      return;
    }
    clearAndFocusFirst(event.target);
  };

  const generateCell = (i: number, last = false) => {
    return (
      <input
        key={i}
        className="focus:ring-focus focus:border-primary-500 h-12 w-12 flex-1 rounded-md border border-gray-300 text-center text-2xl font-bold focus:outline-none focus:ring-2 focus:ring-offset-2"
        type="text"
        maxLength={1}
        onClick={(e) => e.currentTarget.select()}
        value={code[i]}
        onKeyUp={onKeyUp.bind(this, i)}
        onChange={onCodeChanged.bind(this, i)}
        autoFocus={i === 0}
        onBlur={onBlur.bind(this, last)}
      />
    );
  };

  return (
    <div className="flex w-full gap-2">
      {Array.from(Array(props.length / 2).keys()).map((i) => generateCell(i))}
      <div className="flex items-center">-</div>
      {Array.from(Array(props.length / 2).keys()).map((i) =>
        generateCell(i + props.length / 2, i === props.length / 2 - 1)
      )}
    </div>
  );
};
