export interface ShortcutProps {
  command?: boolean;
  value: string;
}

export const Shortcut = (props: ShortcutProps) => {
  const isMac = navigator.userAgent.includes('Mac');
  return (
    <>
      {isMac && (
        <span className="material-icons" style={{ fontSize: '14px' }}>
          keyboard_command_key
        </span>
      )}
      {!isMac && (
        <span className="material-icons" style={{ fontSize: '14px' }}>
          keyboard_control_key
        </span>
      )}
      <span style={{ fontSize: '12px' }}>{props.value}</span>
    </>
  );
};
