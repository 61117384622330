import {
  Button,
  GhostButton,
  Input,
  LoadSpinner,
  Option,
  Select
} from '@kidsmanager/ui-core';
import { IUser } from '@kidsmanager/util-models';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { OutletContextAdminUser } from './outlet-context-admin-user';
import {
  minUsernameLength,
  validateUsername,
  validateEmail
} from './helpers/validators';
import { AdminUserBase } from './admin-user-base';
import { EmailStatus } from './components/email-status';

export const AdminUserProfile = () => {
  const userForm = useRef<HTMLFormElement>(null);
  const context = useOutletContext<OutletContextAdminUser>();
  const navigate = useNavigate();

  const { selectedUser, groups } = context;
  const [isDirty, setIsDirty] = useState(false);
  const [isEmailDirty, setIsEmailDirty] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [usernameHint, setUsernameHint] = useState<string>();
  const [emailHint, setEmailHint] = useState<string>();

  useEffect(() => {
    setIsDirty(false);
    setIsEmailDirty(false);
    setIsSaving(false);
  }, [selectedUser]);

  const activeUsernames = useMemo(
    () => context.activeUsers.map((u) => u.username || ''),
    [context.activeUsers]
  );
  const lockedUsernames = useMemo(
    () => context.lockedUsers.map((u) => u.username || ''),
    [context.lockedUsers]
  );
  const managers = useMemo(
    () =>
      context.activeUsers
        .filter((u) => u.roles?.some((r) => r === 'leader' || r === 'manager'))
        .filter((u) => u.userId !== selectedUser.userId)
        .map((u) => ({
          id: u.userId,
          name: `${u.lastName}, ${u.firstName}`
        })) || [],
    [context.activeUsers, selectedUser.userId]
  );

  const getUserFromForm = (formEvent: HTMLFormElement): IUser | undefined => {
    const data = new FormData(formEvent);
    return {
      userId: selectedUser.userId,
      username: data.get('username')?.toString(),
      lastName: data.get('lastName')?.toString(),
      firstName: data.get('firstName')?.toString(),
      phone: data.get('phone')?.toString(),
      email: data.get('email')?.toString(),
      managerId: data.get('managerId')?.toString(),
      primaryGroupId: data.get('primaryGroupId')?.toString()
    } as IUser;
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const user = getUserFromForm(e.currentTarget);
    if (user) {
      setIsSaving(true);
      context.onChange(user);
    }
  };

  const handleOnChange = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formUser = getUserFromForm(e.currentTarget);
    if (context.newUser) {
      const usernameWarning = validateUsername(
        formUser?.username,
        activeUsernames,
        lockedUsernames
      );
      setUsernameHint(usernameWarning);
      setIsDirty(
        !usernameWarning &&
          (formUser?.username?.length || 0) >= minUsernameLength
      );
    } else {
      setIsDirty(
        selectedUser.firstName !== formUser?.firstName ||
          selectedUser.lastName !== formUser?.lastName ||
          selectedUser.email !== formUser?.email ||
          selectedUser.phone !== formUser?.phone ||
          selectedUser.managerId !== formUser?.managerId ||
          selectedUser.primaryGroupId !== formUser?.primaryGroupId
      );
      setIsEmailDirty(selectedUser.email !== formUser?.email);
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLFormElement>) => {
    const formUser = getUserFromForm(e.currentTarget);
    if (context.newUser) {
      const warning = validateUsername(
        formUser?.username,
        activeUsernames,
        lockedUsernames,
        minUsernameLength
      );
      setUsernameHint(warning);
      setIsDirty(!warning);
    }
    const emailWarning = validateEmail(formUser?.email);
    setEmailHint(emailWarning);
  };

  const handleReset = () => {
    if (context.newUser) {
      navigate('/admin/users');
    } else {
      setIsDirty(false);
      setIsEmailDirty(false);
    }
  };

  return (
    <AdminUserBase
      modules={context.modules}
      selectedUser={context.selectedUser}
    >
      <form
        ref={userForm}
        autoComplete="off"
        key={selectedUser.userId}
        className="flex flex-col gap-3"
        onBlur={handleBlur.bind(this)}
        onSubmit={handleSubmit.bind(this)}
        onChange={handleOnChange.bind(this)}
        onReset={handleReset.bind(this)}
      >
        {context.newUser && (
          <div className="relative">
            <Input
              name="username"
              label="Benutzername (Erforderlich)"
              placeholder="z.B. a.muster"
              spellCheck={false}
              mask="username"
              hint={usernameHint}
            />
          </div>
        )}
        <Input
          name="firstName"
          label="Vorname"
          defaultValue={selectedUser.firstName}
        />
        <Input
          name="lastName"
          label="Nachname"
          defaultValue={selectedUser.lastName}
        />
        <Input
          name="phone"
          label="Telefonnummer"
          defaultValue={selectedUser.phone}
          mask="phone"
        />
        <Input
          name="email"
          label="E-Mail"
          defaultValue={selectedUser.email}
          mask="email"
          hint={emailHint}
          suffix={
            <EmailStatus isDirty={isEmailDirty} value={context.emailState} />
          }
        />
        <Select
          name="managerId"
          label="Vorgesetzte&#47;r"
          defaultValue={selectedUser.managerId}
        >
          <Option value="">-- Kein Vorgesetzter --</Option>
          {managers?.map((m) => (
            <Option key={m.id} value={m.id}>
              {m.name}
            </Option>
          ))}
        </Select>
        <Select
          name="primaryGroupId"
          label="Primärgruppe"
          defaultValue={selectedUser.primaryGroupId}
        >
          <Option key="" value="">
            -- Keine Gruppe --
          </Option>
          {groups.map((g) => (
            <Option key={g.groupId} value={g.groupId}>
              {g.name}
            </Option>
          ))}
        </Select>
        <div className="my-4 flex gap-2">
          <GhostButton type="reset">Abbrechen</GhostButton>
          <LoadSpinner show={isSaving} position="right">
            <Button type="submit" disabled={!isDirty}>
              {context.newUser ? 'Konto Erstellen' : 'Speichern'}
            </Button>
          </LoadSpinner>
        </div>
      </form>
    </AdminUserBase>
  );
};
