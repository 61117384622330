import { useContext, useEffect, useMemo, useState } from 'react';
import { InputCode } from '../components/input-code';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Checkbox } from '@kidsmanager/ui-core';
import { ClientBackendContext } from '@kidsmanager/ui-api';
import { redirectUrl } from '../helpers/linking';
import { extractMfaPayload } from '../helpers/mfa-decoder';

export interface FeatureMfaEmailProps {
  linkWithLegacy?: boolean;
}

export const FeatureMfaEmail = (props: FeatureMfaEmailProps) => {
  const [token, setToken] = useState('');
  const [check, setCheck] = useState('');
  const [tenant, setTenant] = useState('');
  const [mismatch, setMismatch] = useState(false);
  const [trust, setTrust] = useState(false);

  const client = useContext(ClientBackendContext);
  const params = useParams<{ returnUrl: string }>();
  const navigate = useNavigate();

  const identity = useMemo(() => client.auth.identity(), [client]);

  useEffect(() => {
    const token = sessionStorage.getItem('mfaChallenge');
    if (!token) {
      return;
    }
    setToken(token);
    const payload = extractMfaPayload(token);
    const currentTime = Math.floor(Date.now() / 1000);
    if (currentTime > payload.exp) {
      sessionStorage.removeItem('mfaChllenge');
      navigate('/login');
    } else {
      setCheck(payload.check);
      setTenant(payload.tenant);
    }
  }, [navigate]);

  const handleChange = async (code: string) => {
    if (code.length < 6) {
      setMismatch(false);
      return;
    }
    const response = await fetch('/oauth2/mfa/complete', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ type: 'email', token, code, identity, trust })
    });
    if (response.ok) {
      client.auth.processAuthResponse(await response.json(), false);
      navigate(
        redirectUrl(props.linkWithLegacy && tenant !== 'dev', params.returnUrl)
      );
      setMismatch(false);
    } else {
      setMismatch(true);
    }
  };

  return (
    <div className="mx-auto">
      <p>
        Eine Nachricht mit einem Sicherheitscode wurde dir per E-Mail gesandt
      </p>
      <p className="my-4">Vergleichswert: {check}</p>
      <div className="mx-auto mb-4 mt-6 max-w-sm">
        <InputCode length={6} onChange={handleChange.bind(this)} mismatch />
      </div>
      <div className="h-8">
        {mismatch && <p className="text-error text-base">Ungültiger Code</p>}
      </div>
      <div className="my-4 text-sm">
        <Checkbox onChange={setTrust.bind(this)}>
          Diesem Computer vertrauen
        </Checkbox>
      </div>

      <div className="text-blue-400 hover:underline">
        <Link to="/login/mfa/select">Weitere Anmeldemethoden</Link>
      </div>
    </div>
  );
};
