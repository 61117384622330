import { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { ShiftRule } from './components/shift-rule';
import { WorkRulesEngine } from '@kidsmanager/util-rules';
import { ContextAdminRosterOutlet } from './context-admin-roster-outlet';
import { IRosterRuleValue } from '@kidsmanager/util-models';
import { RuleCategory } from 'libs/util-rules/src/lib/rule-base';

interface RuleDefinition {
  id: string;
  description: string;
  category: string;
  values: IRosterRuleValue[];
  enabled: boolean;
}

const ruleCategories: { key: RuleCategory; label: string }[] = [
  { key: 'daily', label: 'Täglich' },
  { key: 'weekly', label: 'Wöchentlich' },
  { key: 'rest', label: 'Ruhezeiten' },
  { key: 'other', label: 'Andere' }
];

export const AdminRosterRules = () => {
  const context = useOutletContext<ContextAdminRosterOutlet>();
  const { template } = context;
  const [rules, setRules] = useState<Map<string, RuleDefinition[]>>(new Map());

  useEffect(() => {
    if (!template) {
      return;
    }
    setRules(() => new Map());
    //push setting to the next event loop to allow cancel changes
    setTimeout(() => {
      const engine = new WorkRulesEngine();
      const ruleMap = new Map<string, RuleDefinition[]>();
      engine.rules.forEach((rule) => {
        rule.specs().forEach((spec) => {
          const config = template.rules.find((x) => x.id === spec.id);
          const values = config?.values || spec.defaults;
          const ruleDef: RuleDefinition = {
            description: spec.description,
            category: spec.category,
            id: spec.id,
            values,
            enabled: config?.enabled || false
          };
          if (!ruleMap.has(spec.category)) {
            ruleMap.set(spec.category, []);
          }
          ruleMap.get(spec.category)?.push(ruleDef);
        });
      });
      setRules(ruleMap);
    }, 0);
  }, [template]);

  const handleChange = (
    ruleId: string,
    values: IRosterRuleValue[],
    enabled: boolean
  ) => {
    const index = template?.rules.findIndex((x) => x.id === ruleId);
    if (index === -1) {
      template.rules.push({
        id: ruleId,
        values: [...values],
        enabled
      });
    } else {
      template.rules[index].values = [...values];
      template.rules[index].enabled = enabled;
    }
    context.onChange?.();
  };

  return (
    rules && (
      <div className="pl-1">
        {ruleCategories.map((category) => (
          <>
            <h2 className="mt-4 text-xs uppercase">{category.label}</h2>
            {rules
              .get(category.key)
              ?.map((rule) => (
                <ShiftRule
                  key={`${template?.id}-${rule.id}`}
                  description={rule.description}
                  values={rule.values}
                  enabled={rule.enabled}
                  onChange={(values, enabled) =>
                    handleChange(rule.id, values, enabled)
                  }
                />
              ))}
          </>
        ))}
      </div>
    )
  );
};
