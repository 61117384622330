export const arrayEqual = (
  a: string[] | undefined,
  b: string[] | undefined
): boolean => {
  if (a === undefined) {
    return b === undefined;
  }
  const bSorted = b?.sort() || [];
  return (
    a.length === b?.length &&
    a.sort().every((val, index) => val === bSorted[index])
  );
};
