export const extractMfaPayload = (token: string | undefined | null) => {
  if (!token) {
    return { exp: 0, check: '', iss: '', tenant: '' };
  }
  const [, urlPayload] = token.split('.');
  const base64Payload = urlPayload.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64Payload)
      .split('')
      .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join('')
  );
  const payload = JSON.parse(jsonPayload) as {
    exp: number;
    check: string;
    iss: string;
  };
  const tenant = payload.iss.substring(payload.iss.lastIndexOf('/') + 1);
  return { ...payload, tenant };
};
