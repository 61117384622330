import { Assignment, ShiftScope } from '../rule-base';

export const longestWorkStreak = (worked: number[]): number => {
  let workStreak = 0;
  let restStreak = 0;
  let longestWorkStreak = 0;
  for (let i = 0; i < worked.length; i++) {
    if (worked[i] === 1) {
      workStreak += 1;
      restStreak = 0;
    } else {
      restStreak += 1;
      if (restStreak > 1) {
        longestWorkStreak = Math.max(longestWorkStreak, workStreak);
        workStreak = 0;
      }
    }
  }
  return Math.max(longestWorkStreak, workStreak);
};

const bits = 32;
/**
 * Returns a bit array representing the plan, each day in represent by 3 bytes
 * 96 bits, 1 bit per 15 minute slot
 * @param plan
 * @returns
 */
export const planToMask = (plan: Assignment[][]): number[] => {
  //convert plan into bit array for the test period
  //each bit represents a 15 minute slot
  //each day is 24 hours * 4 slots = 96 bits => 3 bytes
  const length = plan.length * 3;
  const bitArray = new Array<number>(length).fill(0);
  for (let i = 0; i < plan.length; i++) {
    for (let j = 0; j < plan[i].length; j++) {
      const assignment = plan[i][j];
      const start = assignment.hours * 4 + assignment.mins / 15;
      const end = start + assignment.duration / 15;
      for (let k = start; k < end; k++) {
        const index = i * 24 * 4 + k;
        const bitIndex = (index / bits) | 0;
        const bitShift = index % bits;
        bitArray[bitIndex] |= 1 << bitShift;
      }
    }
  }
  return bitArray;
};

export const testToMask = (length: number, test: ShiftScope): number[] => {
  const bitArray = new Array<number>(length).fill(0);
  const start = test.hours * 4 + test.mins / 15;
  const end = start + test.duration * 4;
  const startIndex = (start / bits) | 0;
  const startShift = start % bits;
  const endIndex = (end / bits) | 0;
  const endShift = end % bits;
  if (startIndex === endIndex) {
    //single block case
    bitArray[startIndex] = (1 << (endShift - startShift)) - 1;
  } else {
    //multi block case, set first and last, then loop through middle blocks
    bitArray[startIndex] = 0xffffffff >>> startShift;
    bitArray[endIndex] = 0xffffffff << (bits - endShift);
    for (let i = startIndex + 1; i < endIndex; i++) {
      bitArray[i] = 0xffffffff;
    }
  }
  return bitArray;
};

export const shiftByDays = (mask: number[], days: number): void => {
  const shift = 3 * days;
  mask.unshift(...Array(shift).fill(0));
  mask.splice(mask.length - shift, shift);
};

export const hasOverlap = (a: number[], b: number[]): boolean => {
  //a and b are bit arrays, check if any bit is set in both
  for (let i = 0; i < a.length; i++) {
    if (a[i] & b[i]) {
      return true;
    }
  }
  return false;
};
