import { Link } from 'react-router-dom';

export interface HeaderMenuContainerProps {
  children?: React.ReactNode;
  position?: 'left' | 'right';
  top?: number;
}

export const HeaderMenuContainer = (props: HeaderMenuContainerProps) => {
  const position = props.position === 'left' ? 'left-2' : 'right-0';
  return (
    <ul
      className={`shadow-hdr border-silver-300 absolute top-5 z-50 ${position} hidden border bg-white py-2 group-focus-within:block`}
      style={{ top: props.top ? `${props.top}px` : undefined }}
    >
      {props.children}
    </ul>
  );
};

export const HeaderMenuItem = (props: {
  title: string;
  href: string;
  selected?: boolean;
}) => {
  const handleClick = () => {
    (document.activeElement as HTMLElement).blur();
  };

  return (
    <li className="text-hdr min-w-14 focus-within:bg-[#b8cbdb] hover:bg-[#b8cbdb]">
      <Link
        className="block text-nowrap px-2 py-0.5 outline-none"
        style={{ color: props.selected ? '#26a0da' : undefined }}
        to={props.href}
        onClick={handleClick.bind(this)}
      >
        {props.title}
      </Link>
    </li>
  );
};
