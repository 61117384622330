import { RuleBase, RuleSpec, RulesEngineResult } from '../rule-base';

export class RuleRestPeriods extends RuleBase {
  test(): RulesEngineResult {
    return { status: 'ok' };
  }
  specs(): RuleSpec[] {
    return [
      {
        id: 'rest-daily',
        category: 'rest',
        description:
          'Zwischen zwei Dienste muss eine Ruhezeit von {hours} Stunden liegen',
        defaults: [{ key: 'hours', type: 'number', value: '11' }]
      },
      {
        id: 'rest-weekly',
        category: 'rest',
        description:
          'Eine Wochenruhezeit von {days} aufeinanderfolgenden Tagen ist erforderlich',
        defaults: [{ key: 'days', type: 'number', value: '2' }]
      }
    ];
  }
}
