export interface RosterDayLabelProps {
  date: Date;
  asColumn?: boolean;
}

export const RosterDayLabel = (props: RosterDayLabelProps) => {
  const dayOfWeek = props.date.getDay();
  const dayOfMonth = props.date.getDate().toString().padStart(2, '0');
  const label = dayOfWeek === 0 ? 'So' : dayOfWeek === 6 ? 'Sa' : '';
  return props.asColumn ? (
    <div className="px-2 text-center text-xs">
      <div>{label || <span>&nbsp;</span>}</div>
      <div>{dayOfMonth}</div>
    </div>
  ) : (
    <div className="text-nowrap pl-4 pr-2 text-right text-xs">
      <span className="mr-1">{label}</span>
      <span>{dayOfMonth}</span>
    </div>
  );
};
