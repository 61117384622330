import { ClientBackendContext, totpSecret } from '@kidsmanager/ui-api';
import { Button, Input, QrCode } from '@kidsmanager/ui-core';
import { useContext, useEffect, useMemo, useState } from 'react';

export const FeatureCredentialsMfa = () => {
  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const [deviceRegistered, setDeviceRegistered] = useState<
    boolean | undefined
  >();

  const client = useContext(ClientBackendContext);
  const secret = useMemo(() => totpSecret(), []);

  useEffect(() => {
    client.settings
      .profile()
      .then((x) => setDeviceRegistered(x.mfaDeivceRegistered));
    client.settings.accessMap().then((x) => setName(x.user.firstName));
  }, [client]);

  const handleQrCodeChange = async (value: string) => {
    const code = value.replace(/\D/g, '').substring(0, 6);
    if (code.length === 6) {
      if (await client.settings.registerMfa(secret, code)) {
        setDeviceRegistered(true);
        setError('');
      } else {
        setDeviceRegistered(false);
        setError('Falscher oder abgelaufener Code');
      }
    } else {
      setError('');
    }
    setCode(code);
  };

  const handleRemoveDevice = async () => {
    await client.settings.unregisterMfa();
    setDeviceRegistered(false);
  };

  return (
    <div>
      <h1 className="mb-1 text-xl">2FA-Smartphone-App</h1>
      {deviceRegistered === false && (
        <div>
          <p className="text-sm">
            <span>Scanne in der </span>
            <a
              className="text-blue-500"
              target="_blank"
              rel="noreferrer"
              href="https://www.microsoft.com/en/security/mobile-authenticator-app"
            >
              Microsoft Authenticator-
            </a>
            <span> oder </span>
            <a
              className="text-blue-500"
              target="_blank"
              rel="noreferrer"
              href="https://en.wikipedia.org/wiki/Google_Authenticator"
            >
              Google Authenticator-
            </a>
            <span>App</span>
          </p>
          <div className="my-4 inline-block rounded-md border border-neutral-200">
            <QrCode
              url={`otpauth://totp/KidsManager%3A${name}?secret=${secret}&issuer=KidsManager`}
            />
          </div>
          <div className="max-w-xs">
            <Input
              placeholder="Code aus der Authenticator-App"
              value={code}
              onChange={handleQrCodeChange.bind(this)}
              hint={error}
            />
          </div>
        </div>
      )}
      {deviceRegistered === true && (
        <div className="p-4">
          <Button onClick={handleRemoveDevice.bind(this)}>
            Registrierung löschen
          </Button>
        </div>
      )}
    </div>
  );
};
