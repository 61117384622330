import { useEffect, useState } from 'react';

export interface PasswordCheckerProps {
  value: string;
  onChange?: (valid: boolean) => void;
}

const generateRule = (condition: boolean, text: string) => {
  return (
    <li className="flex items-center gap-1">
      <span
        className={`material-icons ${
          condition ? 'text-green-500' : 'text-red-600'
        }`}
      >
        {condition ? 'check_circle' : 'cancel'}
      </span>
      {text}
    </li>
  );
};

export const PasswordChecker = (props: PasswordCheckerProps) => {
  const [capitalLetter, setCapitalLetter] = useState(false);
  const [smallLetter, setSmallLetter] = useState(false);
  const [number, setNumber] = useState(false);
  const [specialCharacter, setSpecialCharacter] = useState(false);
  const [length, setLength] = useState(false);

  useEffect(() => {
    const hasCapitalLetter = /[A-Z]/.test(props.value);
    const hasSmallLetter = /[a-z]/.test(props.value);
    const hasNumber = /[0-9]/.test(props.value);
    const hasSpecialCharacter = /[^A-Za-z0-9]/.test(props.value);
    const hasLength = props.value.length >= 8;

    setCapitalLetter(hasCapitalLetter);
    setSmallLetter(hasSmallLetter);
    setNumber(hasNumber);
    setSpecialCharacter(hasSpecialCharacter);
    setLength(hasLength);

    props.onChange?.(
      hasCapitalLetter &&
        hasSmallLetter &&
        hasNumber &&
        hasSpecialCharacter &&
        hasLength
    );
  }, [props]);

  return (
    <div className="max-w-sm rounded-md bg-neutral-100 p-4">
      <h2>Passwort Kriterien:</h2>
      <ul className="flex flex-col gap-1 pl-2">
        {generateRule(capitalLetter, '1 Großbuchstabe')}
        {generateRule(smallLetter, '1 Kleinbuchstabe')}
        {generateRule(number, '1 Zahl')}
        {generateRule(specialCharacter, '1 Sonderzeichen (!@#$%^&*)')}
        {generateRule(length, '8 Zeichen')}
      </ul>
    </div>
  );
};
