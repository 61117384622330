import { FeatureCredentialsMfa } from './feature-credentials-mfa';
import { FeatureCredentialsPassword } from './feature-credentials-password';

export const FeatureCredentials = () => {
  return (
    <div className="flex flex-col gap-14 px-10 py-8">
      <FeatureCredentialsPassword />
      <FeatureCredentialsMfa />
    </div>
  );
};
