import { useMemo } from 'react';

const singular = 'BenutzerIn';
const plural = 'BenutzerInnen';

export interface UserSearchSummaryProps {
  matched: number;
  total: number;
}

export const UserSearchSummary = ({
  matched,
  total
}: UserSearchSummaryProps) => {
  const summary = useMemo(() => {
    if (matched === 0 && total === 0) {
      return `0 ${plural}`;
    }
    if (matched === 0) {
      return `0 ${plural} von ${total}`;
    }

    if (matched === 1 && total === 1) {
      return `1 ${singular}`;
    }
    if (matched === 1) {
      return `1 ${singular} von ${total}`;
    }
    if (matched === total) {
      return `${matched} ${plural}`;
    }
    return `${matched} ${plural} von ${total}`;
  }, [matched, total]);

  return <span>{summary}</span>;
};
