import { UserData } from '../../roster-models';

export interface RosterStatusUserProps {
  user: UserData;
  year: number;
  month: number;
  asColumn?: boolean;
}

const conditionalDecimal = (value: number) => {
  return Math.round(value * 10) / 10;
};

export const RosterStatusUser = (props: RosterStatusUserProps) => {
  const { user } = props;
  const firstDay = new Date(props.year, props.month - 1, 1);

  return (
    <div
      className={`outline-focus group relative cursor-pointer text-center text-[11px] ${props.user.warn === 'low' ? 'bg-orange-400' : props.user.warn === 'high' ? 'bg-red-500' : 'bg-neutral-100'}`}
      tabIndex={0}
    >
      <div
        className={`inline-flex items-center ${props.asColumn ? 'px-0.5 leading-8' : 'leading-4'} ${!props.user.warn ? 'text-black/60' : 'text-white'}`}
      >
        {conditionalDecimal(props.user.available)}h
      </div>
      <div
        className={`font-sm border-silver-100 absolute z-10 hidden -translate-x-1/3 border bg-white px-2 py-4 text-left shadow-lg group-focus-within:block ${props.asColumn ? '-translate-y-1/2 translate-x-8' : 'top-4'}`}
      >
        <div className="grid grid-cols-[120px_auto] gap-1">
          <label>
            {`Arbeitstage im ${firstDay.toLocaleString('de-DE', { month: 'short' })}.`}
          </label>
          <div className="text-right text-black/60">{user.workingDays}</div>
          <label>Wochenstunden</label>
          <div className="text-right text-black/60">
            {user.contractedHrs.toFixed(1)}h
          </div>
          <label>Monatstunden</label>
          <div className="text-right text-black/60">
            {user.hoursInMonth.toFixed(1)}h
          </div>
          <label className="inline-flex items-center">
            &Uuml;bertrag Vormonat
            {Math.abs(user.carryOver) > 15 && (
              <span
                className="material-icons ml-1"
                style={{ fontSize: '11px' }}
              >
                warning
              </span>
            )}
          </label>
          <div className="text-right text-black/60">
            {user.carryOver.toFixed(1)}h
          </div>
          <div className="col-span-2 h-0.5" />
          <label>Anpassung</label>
          <div className="text-right text-black/60">
            {user.adjustment.toFixed(1)}h
          </div>
          <label>Urlaub</label>
          <div className="text-right text-black/60">
            -{user.holidays.toFixed(1)}h
          </div>
          <div className="col-span-2 h-1" />
          <label className="font-semibold">Monatsziel</label>
          <div className="text-right font-semibold text-black/60">
            ={user.goal.toFixed(1)}h
          </div>
        </div>
      </div>
    </div>
  );
};
