import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MonthPicker } from '@kidsmanager/ui-core';
import { ShiftManager } from '../shift-manager/shift-manager';
import {
  ExtendedUserStats,
  RosterEditor
} from '../roster-editor/roster-editor';
import { SelectableShiftSpec } from '../roster-models';
import {
  RosterVersioner,
  VersionState
} from '../roster-versioner/roster-versioner';
import { ClientBackendContext } from '@kidsmanager/ui-api';
import { compileShifts, standardShifts } from './helpers/compiler-shift';
import {
  IMemberAssigments,
  IMembership,
  IReleasedPlan,
  IShiftSpecUpdate,
  ITeamConfig,
  IUserBookings
} from '@kidsmanager/util-models';
import {
  compilePlan,
  updatePlanWithSpecChanges
} from './helpers/compiler-plan';
import { compileStats } from './helpers/compile-stats';
import { TransposeToggle } from '../roster-editor/components/transpose-toggle';

const yearAndMonth = (params: { month?: string }) => {
  if (!params.month) {
    const today = new Date();
    return { year: today.getFullYear(), month: today.getMonth() + 1 };
  } else {
    const [year, month] = params.month.split('-').map(Number);
    return { year, month };
  }
};

export const Roster = () => {
  const [daysAsRows, setDaysAsRows] = useState(
    localStorage.getItem('roster-days-as-rows') === 'true'
  );
  const [config, setConfig] = useState<ITeamConfig>();
  const [specs, setSpecs] = useState<SelectableShiftSpec[]>([]);
  const [members, setMembers] = useState<IMembership[]>([]);
  const [bookings, setBookings] = useState<IUserBookings[]>([]);
  const [publicHols, setPublicHols] = useState<Date[]>([]);
  const [stats, setStats] = useState<ExtendedUserStats[]>([]);
  const [plan, setPlan] = useState<IMemberAssigments[]>([]);
  const [state, setState] = useState<VersionState>('empty');
  const [releases, setReleases] = useState<IReleasedPlan[]>([]);

  const client = useContext(ClientBackendContext);
  const params = useParams<{ group: string; month: string }>();
  const navigate = useNavigate();

  const { year, month } = useMemo(() => yearAndMonth(params), [params]);

  useEffect(() => {
    if (!client || !params.group) return;
    const groupId = params.group;
    Promise.all([
      client.roster.team.config(groupId),
      client.roster.team.members(groupId, year, month),
      client.roster.shifts.list(groupId, year, month),
      client.holiday.publicHolidays(year)
    ]).then(([config, team, specs, publicHols]) => {
      const members = team.map((m) => m.id);
      setMembers(team);
      setConfig(config);
      setPublicHols(publicHols);
      setSpecs(compileShifts(specs, standardShifts()));
      Promise.all([
        client.roster.team.stats(groupId, year, month, members),
        client.holiday.bookingsForUsers(year, month, members),
        client.roster.plan.find(year, month, members),
        client.roster.plan.releases(groupId, year, month)
      ]).then(([stats, bookings, current, releases]) => {
        const { plan, hols, status } = compilePlan(
          year,
          month,
          current,
          bookings,
          publicHols,
          releases
        );
        setStats(compileStats(stats, hols));
        setBookings(bookings);
        setPlan(plan);
        setReleases(releases);
        setState(status);
      });
    });
  }, [client, params.group, year, month]);

  const handleTranspose = () => {
    setDaysAsRows((prev) => {
      localStorage.setItem('roster-days-as-rows', (!prev).toString());
      return !prev;
    });
  };

  const handleMonthChange = (year: number, month: number) => {
    navigate(
      `/team/${params.group}/roster/${year}-${month.toString().padStart(2, '0')}`
    );
  };

  const handleSelected = (spec: SelectableShiftSpec) => {
    setSpecs((prev) =>
      prev.map((s) => {
        s.selected = s.id === spec.id ? true : false;
        return s;
      })
    );
  };

  const handleVisibility = (spec: SelectableShiftSpec, visible: boolean) => {
    spec.hide = !visible;
    setSpecs((prev) => [...prev]);
  };

  const handleSpecsChange = (value: IShiftSpecUpdate) => {
    const update = updatePlanWithSpecChanges(plan, value);
    setSpecs(compileShifts(value.specs, standardShifts()));
    setPlan(update.plan);
    setState('unsaved');
  };

  const handleCancel = async () => {
    if (!params.group) {
      setState('empty');
    } else {
      const ids = members.map((m) => m.id);
      const current = await client.roster.plan.find(year, month, ids);
      const { plan, status } = compilePlan(
        year,
        month,
        current,
        bookings,
        publicHols,
        releases
      );
      setPlan(plan);
      setState(status);
    }
  };

  const handleSave = async () => {
    await client.roster.plan.update(year, month, plan);
    setState('saved');
  };

  const handleOpen = (version: string) => {
    console.log('open', version);
  };

  const handleRelease = async () => {
    if (!params.group) return;
    const ids = members.map((m) => m.id);
    const release = await client.roster.plan.release(
      params.group,
      year,
      month,
      ids
    );
    setReleases((prev) => [release, ...prev]);
    setState('released');
  };

  const handleUnpublish = async (version: string) => {
    if (!params.group) return;

    const ids = members.map((m) => m.id);
    const unpublished = await client.roster.plan.unpublish(
      params.group,
      year,
      month,
      version,
      ids
    );
    setReleases((prev) =>
      prev.filter((r) => r.version !== unpublished.version)
    );
    setState('saved');
  };

  const handleDiscard = async (version: string) => {
    if (!params.group) return;

    const ids = members.map((m) => m.id);
    const deleted = await client.roster.plan.discard(
      params.group,
      year,
      month,
      version,
      ids
    );
    if (version === 'draft') {
      handleCancel();
    } else {
      setReleases((prev) => prev.filter((r) => r.version !== deleted.version));
      setState('saved');
    }
  };
  return year === 0 || !params.group ? undefined : (
    <div className="flex gap-4 p-4">
      <TransposeToggle onClick={handleTranspose.bind(this)} />
      <div className="min-w-52 md:min-w-64">
        <div className="sticky top-5 z-10 pt-5">
          <MonthPicker
            year={year}
            month={month}
            onChange={handleMonthChange.bind(this)}
          />
          <ShiftManager
            group={params.group}
            year={year}
            month={month}
            specs={specs}
            onSelected={handleSelected.bind(this)}
            onToggled={handleVisibility.bind(this)}
            onChange={handleSpecsChange.bind(this)}
          />
          <RosterVersioner
            year={year}
            month={month}
            state={state}
            releases={releases}
            onRelease={handleRelease.bind(this)}
            onUnpublish={handleUnpublish.bind(this)}
            onDiscard={handleDiscard.bind(this)}
            onOpen={handleOpen.bind(this)}
            onSave={handleSave.bind(this)}
            onCancel={handleCancel.bind(this)}
          />
        </div>
      </div>
      <RosterEditor
        year={year}
        month={month}
        config={config}
        specs={specs}
        members={members}
        stats={stats}
        plan={plan}
        daysAsRows={daysAsRows}
        onChange={() => setState('unsaved')}
      />
    </div>
  );
};
