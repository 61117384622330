import { useOutletContext } from 'react-router-dom';
import { EditorNonWork } from './components/editor-non-work';
import { ContextAdminRosterOutlet } from './context-admin-roster-outlet';
import { IRosterTemplateNonWork } from '@kidsmanager/util-models';

export const AdminRosterNonWork = () => {
  const context = useOutletContext<ContextAdminRosterOutlet>();
  const { template } = context;

  const handleChange = (nonWork: IRosterTemplateNonWork[]) => {
    template.nonWork = nonWork;
    context.onChange?.();
  };

  return (
    template && (
      <EditorNonWork
        options={template.nonWork}
        onChange={handleChange.bind(this)}
      />
    )
  );
};
