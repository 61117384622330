import { Checkbox } from '@kidsmanager/ui-core';
import { InputCode } from '../components/input-code';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ClientBackendContext } from '@kidsmanager/ui-api';
import { redirectUrl } from '../helpers/linking';
import { extractMfaPayload } from '../helpers/mfa-decoder';

export interface FeatureMfaAuthenticatorProps {
  linkWithLegacy?: boolean;
}

export const FeatureMfaAuthenticator = (
  props: FeatureMfaAuthenticatorProps
) => {
  const [mismatch, setMismatch] = useState(false);
  const [trust, setTrust] = useState(false);
  const [tenant, setTenant] = useState('');
  const [token, setToken] = useState('');

  const client = useContext(ClientBackendContext);
  const params = useParams<{ returnUrl: string }>();
  const navigate = useNavigate();
  const identity = useMemo(() => client.auth.identity(), [client]);

  useEffect(() => {
    const token = sessionStorage.getItem('mfaChallenge');
    if (!token) {
      return;
    }
    const { tenant } = extractMfaPayload(token);
    setToken(token);
    setTenant(tenant);
  }, []);

  const handleChange = async (code: string) => {
    if (code.length < 6) {
      setMismatch(false);
      return;
    }
    const response = await fetch('/oauth2/mfa/complete', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        type: 'authenticator',
        token,
        code,
        identity,
        trust
      })
    });
    if (response.ok) {
      client.auth.processAuthResponse(await response.json(), false);
      navigate(
        redirectUrl(props.linkWithLegacy && tenant !== 'dev', params.returnUrl)
      );
      setMismatch(false);
    } else {
      setMismatch(true);
    }
  };

  return (
    <div className="mx-auto">
      <p>Einmalpasswort aus der Smartphone-App</p>
      <div className="mx-auto mb-4 mt-6 max-w-sm">
        <InputCode length={6} onChange={handleChange.bind(this)} mismatch />
      </div>
      <div className="h-8">
        {mismatch && <p className="text-error text-base">Ungültiger Code</p>}
      </div>
      <div className="my-4 text-sm">
        <Checkbox onChange={setTrust.bind(this)}>
          Diesem Computer vertrauen
        </Checkbox>
      </div>
      <div className="text-blue-400 hover:underline">
        <Link to="/login/mfa/select">Weitere Anmeldemethoden</Link>
      </div>
    </div>
  );
};
