import { RuleBase, RuleSpec, RulesEngineResult } from '../rule-base';

export class RuleWeekendNeeds extends RuleBase {
  test(): RulesEngineResult {
    return { status: 'ok' };
  }
  specs(): RuleSpec[] {
    return [
      {
        id: 'weekend-needs-full',
        category: 'rest',
        description:
          'Pro Quartal müssen an {count} Wochenruhezeiten mit Samstag und Sonntag frei sein',
        defaults: [{ key: 'count', type: 'number', value: '3' }]
      },
      {
        id: 'weekend-needs-part',
        category: 'rest',
        description:
          'Pro Quartal müssen {count} Wochenruhezeiten enthalten sein, die entweder Samstag oder Sonntag einschließen',
        defaults: [{ key: 'count', type: 'number', value: '6' }]
      }
    ];
  }
}
