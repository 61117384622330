import {
  RulesEngineResult,
  TicksPerHour,
  RuleBase,
  RuleSpec
} from '../rule-base';

export class RuleMaxHours extends RuleBase {
  test(from: Date, until: Date): RulesEngineResult {
    const hours = (until.getTime() - from.getTime()) / TicksPerHour;
    if (hours > 30) {
      return {
        status: 'fail',
        message: 'Maximal 30 Stunden pro Eintrag sind erlaubt'
      };
    } else if (hours > 25) {
      return {
        status: 'warn',
        message: 'Über 25 Stunden erfordert eine zusätzliche Genehmigung'
      };
    }
    return { status: 'ok' };
  }
  specs(): RuleSpec[] {
    return [
      {
        id: 'max-without-oncall',
        category: 'daily',
        description:
          'Dienste, zwischern {min} unter {max} Stunden, erfordern die Freigabe der Leitung',
        defaults: [
          { key: 'min', type: 'number', value: '10' },
          { key: 'max', type: 'number', value: '13' }
        ]
      },
      {
        id: 'max-hours-day',
        category: 'daily',
        description:
          'Dienste, die über {length} Stunden dauern, erfordern die Freigabe der Leitung',
        defaults: [{ key: 'length', type: 'number', value: '25' }]
      },
      {
        id: 'max-hours-week',
        category: 'weekly',
        description:
          'Arbeiten von über {length} Stunden pro Woche erfordert die Genehmigung der Leitung',
        defaults: [{ key: 'length', type: 'number', value: '60' }]
      }
    ];
  }
}
