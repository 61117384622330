import React, { useEffect, useMemo, useRef, useState } from 'react';
import { IconButton } from '../buttons';
import { Chip } from './chip';
import { Info } from '../info/info';

export interface ChipGroupProps {
  chips: { id: string; label: string }[];
  selectedIds: string[];
  label: string;
  tooltip?: string;
  onChange: (id: string[]) => void;
}

export const ChipGroup = (props: ChipGroupProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [showPanel, setShowPanel] = useState(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [display, setDisplay] = useState<{ id: string; label: string }[]>([]);

  const [selected, available] = useMemo(() => {
    return [
      props.chips.filter((c) => props.selectedIds?.includes(c.id)),
      props.chips.filter((c) => !props.selectedIds?.includes(c.id))
    ];
  }, [props.chips, props.selectedIds]);

  useEffect(() => {
    const handleDocClick = (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        setShowPanel(false);
      }
    };
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setShowPanel(false);
      }
    };
    document.addEventListener('mousedown', handleDocClick);
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('mousedown', handleDocClick);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [ref]);

  useEffect(() => {
    if (!searchValue) {
      setDisplay(available);
    } else {
      const pattern = searchValue.toLowerCase();
      setDisplay(
        available.filter((c) => c.label.toLowerCase().includes(pattern))
      );
    }
  }, [available, searchValue]);

  const handleAddChip = (id: string) => {
    if (id === '*') {
      props.onChange?.(props.chips.map((c) => c.id));
    } else {
      props.onChange?.([...props.selectedIds, id]);
    }
    setShowPanel(false);
  };

  const handleRemoveChip = (id: string) => {
    props.onChange?.(props.selectedIds.filter((s) => s !== id));
  };

  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  return (
    <div className="relative">
      <div className="flex justify-between px-1">
        <span className="text-sm">{props.label}</span>
        {props.tooltip && <Info position="right">{props.tooltip}</Info>}
      </div>
      <div className="border-silver-200 relative max-h-48 min-h-32 overflow-clip rounded-lg border bg-white">
        <div className="absolute inset-0 overflow-y-auto">
          <div className="flex flex-wrap justify-start gap-2 p-2 px-1.5">
            {selected.map((p) => (
              <Chip
                key={p.id}
                id={p.id}
                label={p.label}
                onClick={handleRemoveChip.bind(this, p.id)}
              ></Chip>
            ))}
          </div>
        </div>

        <span className="absolute bottom-0 right-1">
          <IconButton
            color="ghost"
            onClick={() => setShowPanel((prev) => !prev)}
            disabled={available.length === 0}
          >
            add
          </IconButton>
        </span>
      </div>
      {showPanel && (
        <div
          ref={ref}
          className="shadow-hdr animate-dialog absolute right-0 z-10 float-end mt-1 w-1/2 bg-white p-2 text-center"
        >
          <div className="relative">
            <input
              autoComplete="off"
              type="text"
              placeholder={`${props.label} filtern...`}
              className="h-8 w-full rounded-md border border-gray-300 bg-white px-[38px] text-black placeholder-gray-400 focus:outline-none"
              data-dd-privacy="allow"
              value={searchValue}
              onChange={handleSearchInput.bind(this)}
            />
            <span
              style={{ fontSize: '20px' }}
              className="material-icons absolute left-1 top-1/2 flex w-7 -translate-y-1/2 cursor-pointer items-center justify-center rounded-full text-black outline-none"
            >
              search
            </span>
          </div>
          <ul className="mt-1 flex max-h-48 min-h-40 flex-col gap-1 overflow-auto">
            {display.length === 0 && (
              <li className="py-2 text-black/50">Keine Treffer</li>
            )}
            {display.map((chip) => (
              <li
                key={chip.id}
                className="cursor-pointer rounded bg-neutral-100 py-1 hover:bg-neutral-200"
                style={{
                  userSelect: 'none'
                }}
                onClick={handleAddChip.bind(this, chip.id)}
              >
                {chip.label}
              </li>
            ))}
            {!searchValue && display.length > 3 && (
              // Maybe extract li as generated from function with above li
              <li
                className="mt-2 cursor-pointer rounded bg-neutral-100 py-1 hover:bg-neutral-200"
                onClick={handleAddChip.bind(this, '*')}
                style={{
                  userSelect: 'none'
                }}
              >
                Alle {props.label}
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};
