export const ticksPerDay = 86400000;

/**
 * Get the week number of the year based on the FirstFourDayWeek rule.
 * @remarks uses a trick that January 4 is always in week 1.
 */
export const getWeekNumber = (date: Date): number => {
  // Thursday in current week decides the year.
  const thurs = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate() + 3 - ((date.getDay() + 6) % 7)
  );

  // January 4 is always in week 1.
  const jan4 = new Date(thurs.getFullYear(), 0, 4);
  const count = (thurs.getTime() - jan4.getTime()) / ticksPerDay;

  // Adjust to Thursday in week 1 and count number of weeks from date to week1.
  return 1 + Math.round((count - 3 + ((jan4.getDay() + 6) % 7)) / 7);
};

export const firstDateOfWeek = (year: number, week: number) => {
  const firstMonday = new Date(year, 0, 1);
  while (firstMonday.getDay() !== 1) {
    // 1 represents Monday
    firstMonday.setDate(firstMonday.getDate() + 1);
  }
  const firstWeek = getWeekNumber(firstMonday);
  const weekDiff = week - firstWeek;
  return new Date(firstMonday.setDate(firstMonday.getDate() + weekDiff * 7));
};

export const mondayOfWeek = (
  year: number,
  month: number,
  day: number
): Date => {
  let date = new Date(Date.UTC(year, month - 1, day));
  while (date.getDay() != 1) {
    date = addDays(date, -1);
  }
  return date;
};

export const toLocaleISODate = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const addDays = (date: Date, days: number): Date => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

/**
 * Lexically sortable ticks, which when used as sort key
 * will return most recent date first
 * @param value date to convert
 * @returns string representation of ticks
 */
export const sortableTicks = (value: Date): string => {
  //epoch for C# BigInt('621355968000000000')
  //max for C# BigInt('3155378975999999999')
  //so js_max = max - epoch
  const js_max = BigInt('2534023007999999999');
  const ticks = BigInt(value.getTime() * 10000);
  return String(js_max - ticks).padStart(19, '0');
};

export const parseSortableTicks = (value: string): Date => {
  const js_max = BigInt('2534023007999999999');
  const ticks = js_max - BigInt(value);
  return new Date(Number(ticks) / 10000);
};

/**
 * @returns the total seconds of a date
 */
export const getTotalSeconds = (value: Date): number => {
  return (
    value.getHours() * 60 * 60 + value.getMinutes() * 60 + value.getSeconds()
  );
};

/**
 * @returns the timezone offset in seconds
 */
export const timezoneOffsetSeconds = (value: Date): number => {
  return value.getTimezoneOffset() * 60;
};

export const parseUtcDate = (value: string | Date): Date => {
  //HACK: stored as Date or string; in future will be "yyyy-MM-dd"
  //at which point we can clean up this function
  if (typeof value === 'string') {
    const [year, month, day] = value.split(/[-.]/).map((x) => parseInt(x, 10));
    return new Date(Date.UTC(year, month - 1, day));
  } else {
    return new Date(value);
  }
};

export const isWeekend = (date: Date): boolean => {
  date = new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  );
  return date.getDay() === 0 || date.getDay() === 6;
};

export const isWorkingDay = (date: Date, publicHolidays: Date[]): boolean => {
  if (isWeekend(date)) return false;
  return publicHolidays.findIndex((x) => equalDays(date, x)) === -1;
};

export const includesDay = (dates: Date[], date: Date): boolean => {
  return !!dates.find(
    (x) =>
      x.getFullYear() === date.getFullYear() &&
      x.getMonth() === date.getMonth() &&
      x.getDate() === date.getDate()
  );
};

export const equalDays = (a: Date, b: Date): boolean => {
  return (
    a.getFullYear() === b.getFullYear() &&
    a.getMonth() === b.getMonth() &&
    a.getDate() === b.getDate()
  );
};

export const daysFromToday = (date: Date): number => {
  const today = new Date(Date.now());
  return Math.floor(
    (Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) -
      Date.UTC(today.getFullYear(), today.getMonth(), today.getDate())) /
      ticksPerDay
  );
};

export const workDaysBetween = (from: Date, to: Date): number => {
  let count = 0;
  const start = new Date(from);
  while (start <= to) {
    if (start.getDay() !== 0 && start.getDay() !== 6) {
      count++;
    }
    start.setDate(start.getDate() + 1);
  }
  return count;
};

export const workDaysContained = (
  dates: Date[],
  from: Date,
  to: Date
): number => {
  return dates.reduce((acc, date) => {
    if (date.getDay() === 0) return acc;
    if (date.getDay() === 6) return acc;
    return date >= from && date <= to ? acc + 1 : acc;
  }, 0);
};

export const ToTimeString = (seconds: number) => {
  const hours = Math.floor(seconds / 3600) % 24;
  const mins = Math.floor((seconds % 3600) / 60);
  return `${hours.toString().padStart(2, '0')}:${mins
    .toString()
    .padStart(2, '0')}`;
};

export const toUserReadableRange = (from: Date, to: Date): string => {
  const fromYear = from.getFullYear();
  const fromMonth = String(from.getMonth() + 1).padStart(2, '0');
  const fromDay = String(from.getDate()).padStart(2, '0');

  const toYear = to.getFullYear();
  const toMonth = String(to.getMonth() + 1).padStart(2, '0');
  const toDay = String(to.getDate()).padStart(2, '0');

  const days = (to.getTime() - from.getTime()) / ticksPerDay;
  if (days < 2) {
    return `${fromDay}.${fromMonth}.${fromYear}`;
  }
  if (fromYear !== toYear) {
    return `${fromDay}.${fromMonth}.${fromYear}-${toDay}.${toMonth}.${toYear}`;
  }
  if (fromMonth !== toMonth) {
    return `${fromDay}.${fromMonth}-${toDay}.${toMonth}.${fromYear}`;
  }
  return `${fromDay}-${toDay}.${fromMonth}.${fromYear}`;
};
