import { useEffect, useState } from 'react';

interface MasterDetailWidths {
  master?: number;
  detail?: number;
}

export interface MasterDetailProps {
  master: React.ReactNode;
  detail?: React.ReactNode;
  widths?: MasterDetailWidths;
}

export const MasterDetail = (props: MasterDetailProps) => {
  const establishWidth = (target?: MasterDetailWidths) => {
    if (!target) {
      return 0.5 * window.innerWidth;
    }
    if (target.master && target.master * 2 < window.innerWidth) {
      return target.master;
    } else if (target.detail && target.detail * 2 < window.innerWidth) {
      return window.innerWidth - target.detail;
    } else {
      return 0.5 * window.innerWidth;
    }
  };

  const [isResizing, setIsResizing] = useState(false);
  const [dividerPosition, setDividerPosition] = useState(
    -1 * establishWidth(props.widths)
  );

  useEffect(() => {
    if (props.widths?.master) {
      return;
    }
    const handleResize = () => {
      const newPosition = establishWidth(props.widths);
      setDividerPosition((prev) => {
        const adjust = Math.abs(Math.abs(prev) - newPosition) > 20;
        if (adjust) {
          return prev < 0 ? -1 * newPosition : newPosition;
        } else {
          return prev;
        }
      });
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [props.widths]);

  useEffect(() => {
    // gives the cool slide in effect; starts off with negative value and then transitions to positive
    setDividerPosition((prev) => Math.abs(prev) * (props.detail ? 1 : -1));
  }, [props.detail]);

  const handleMouseMove = (e: MouseEvent) => {
    const x = e.clientX;
    if (window.innerWidth * 0.2 < x && x < window.innerWidth * 0.8) {
      setDividerPosition(x);
    }
  };
  const handleMouseUp = () => {
    setIsResizing(false);
    window.removeEventListener('mousemove', handleMouseMove);
    window.removeEventListener('mouseup', handleMouseUp);
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsResizing(true);
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
  };

  return (
    <div className="fixed inset-0 flex w-full pt-7">
      <div
        className={`overflow-y-auto ${!isResizing && 'transition-all duration-300 ease-in-out'} ${props.detail ? 'flex-shrink-0' : 'flex-grow'}`}
        style={{ flexBasis: props.detail ? `${dividerPosition}px` : '100%' }}
      >
        {props.master}
      </div>
      {props.detail && (
        <div
          className="w-1 cursor-ew-resize bg-neutral-100"
          onMouseDown={handleMouseDown}
        />
      )}
      <div
        className={`overflow-auto bg-neutral-50 ${!isResizing && 'transition-all duration-300 ease-in-out'} ${props.detail ? 'flex-grow p-4 opacity-100' : 'w-0 opacity-0'}`}
        style={{
          flexBasis: props.detail ? `calc(100% - ${dividerPosition}px)` : '0'
        }}
      >
        {props.detail}
      </div>
    </div>
  );
};
