export interface RosterCursorProps {
  x: number;
  y: number;
  yoffset: number;
  xoffset: number;
  disabled?: boolean;
}

export const UnsetCursor = {
  x: -10,
  y: -10,
  yoffset: 0,
  xoffset: 0
};

export type updateCallback = (x: number, y: number) => void;

export const RosterCursor = ({ x, y, yoffset, xoffset }: RosterCursorProps) => {
  return (yoffset === 0 && xoffset) === 0 ? undefined : (
    <div className="absolute inset-0">
      <div
        className="absolute w-full border border-dashed border-sky-400/40"
        style={{ left: xoffset, top: `${y}px`, width: `${x - xoffset + 10}px` }}
      />
      <div
        className="absolute h-full border border-dashed border-sky-400/40"
        style={{
          top: yoffset - 10,
          left: `${x}px`,
          height: `${y - yoffset + 20}px`
        }}
      />
    </div>
  );
};
