import { Input, Option, Select } from '@kidsmanager/ui-core';
import { SelectableShiftSpec } from '../../roster-models';
import { useEffect, useState } from 'react';
import { nameToColor } from '@kidsmanager/util-common';
import { IRosterTemplateShift } from '@kidsmanager/util-models';
import { unpackHours } from '../helpers/shift-mananger-helpers';

export interface EditableShiftSpecProps {
  spec: SelectableShiftSpec;
  patterns: IRosterTemplateShift[];
  onDeleted?: (index: number | undefined) => void;
  onBlur?: (spec: SelectableShiftSpec) => void;
}

export const EditableShiftSpec = (props: EditableShiftSpecProps) => {
  const [spec, setSpec] = useState<SelectableShiftSpec>();
  useEffect(() => {
    setSpec(props.spec);
  }, [props.spec]);

  if (!spec || props.patterns.length === 0) {
    //do not render until we get the patterns or else the select will not present correctly
    return null;
  }

  const handleBlur = (e: React.FocusEvent<HTMLElement>) => {
    const data = new FormData(e.target.closest('form') as HTMLFormElement);
    const updated = {
      ...spec,
      ...unpackHours(data.get('start'), data.get('hrs'))
    };
    props.onBlur?.(updated);
  };

  const handleChange = (e: React.ChangeEvent<HTMLFormElement>) => {
    if (spec && e.target.name === 'name') {
      const value = e.target.value;
      const updated = { ...spec, name: value, color: nameToColor(value) };
      props.onBlur?.(updated);
    }
  };

  return (
    <li>
      <form
        className="m-1 grid grid-cols-[20px_1fr_70px_70px_40px] items-center gap-3"
        onBlur={handleBlur.bind(this)}
        onChange={handleChange.bind(this)}
      >
        <div
          className="h-6 w-6 rounded"
          style={{ backgroundColor: spec.color }}
        ></div>
        <Select name="name" defaultValue={spec.name}>
          {props.patterns.map(({ id, name }) => (
            <Option key={id} value={name}>
              {name}
            </Option>
          ))}
        </Select>
        <Input name="start" defaultValue={spec.start} mask="time" />
        <Input
          name="hrs"
          defaultValue={spec.hrs.toString()}
          type="number"
          min={0}
          max={48}
        />
        <span
          className="material-icons cursor-pointer text-black/50"
          onClick={props.onDeleted?.bind(this, spec.index)}
        >
          delete
        </span>
      </form>
    </li>
  );
};
