import { IEmailState } from '@kidsmanager/util-models';
import { useEffect, useMemo, useRef, useState } from 'react';

export interface EmailStatusProps {
  isDirty: boolean;
  value?: IEmailState;
}

const style = {
  verified: { icon: 'check', color: '#84cc16' },
  pending: { icon: 'timer', color: '#3b82f6' },
  error: { icon: 'warning', color: '#f87272' }
};

const toFormattedDate = (date: string | undefined) => {
  if (!date) {
    return undefined;
  }
  const d = new Date(date);
  const today = new Date();
  if (d.getFullYear() === today.getFullYear()) {
    return `${d.toLocaleDateString('de-DE', { day: '2-digit', month: 'short' })} ${d.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' })}`;
  } else {
    return `${d.toLocaleDateString('de-DE', {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit'
    })}, ${d.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' })}`;
  }
};

export const EmailStatus = (props: EmailStatusProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [panelOpen, setPanelOpen] = useState(false);
  const [value, setValue] = useState<IEmailState | undefined>();

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (!ref.current?.contains(event.target as Node)) {
        setPanelOpen(false);
      } else {
        if ((event.target as HTMLElement).tagName === 'BUTTON') {
          setPanelOpen((prev) => !prev);
        }
        event.preventDefault();
        event.stopPropagation();
      }
    };
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setPanelOpen(false);
      }
    };
    document.addEventListener('click', handleClick);
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('click', handleClick);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (props.isDirty || !props.value) {
      setValue(undefined);
    } else {
      setValue(props.value);
    }
  }, [props.value, props.isDirty]);

  const data = useMemo(() => {
    return {
      added: toFormattedDate(value?.added),
      verified: toFormattedDate(value?.verified),
      lastUsed: toFormattedDate(value?.lastUsed)
    };
  }, [value]);

  const buildContent = (value?: IEmailState) => {
    if (!value) {
      return undefined;
    }
    if (value.state === 'unverified') {
      return undefined;
    }
    switch (value.state) {
      case 'pending':
        return (
          <>
            <h2 className="font-bold">Bestätigung ausstehend</h2>
            <table className="min-w-72">
              <tbody>
                <tr>
                  <td className="py-1 pr-2">Hinzugefügt</td>
                  <td className="text-right">{data.added}</td>
                </tr>
              </tbody>
            </table>
          </>
        );
      case 'verified':
        return (
          <>
            <h2 className="font-bold">E-Mail OK</h2>
            <table className="min-w-72">
              <tbody>
                {data.lastUsed && (
                  <tr>
                    <td className="py-1 pr-2">Letzte Zustellung</td>
                    <td className="text-right">{data.lastUsed}</td>
                  </tr>
                )}
                {data.verified && (
                  <tr>
                    <td className="py-1 pr-2">Bestätigt</td>
                    <td className="text-right">{data.verified}</td>
                  </tr>
                )}
                <tr>
                  <td className="py-1 pr-2">Hinzugefügt</td>
                  <td className="text-right">{data.added}</td>
                </tr>
              </tbody>
            </table>
          </>
        );
      case 'error':
        return (
          <>
            <h2 className="font-bold">E-Mail-Zustellfehler</h2>
            <table className="min-w-72">
              <tbody>
                <tr>
                  <td colSpan={2}>{value.lastError}</td>
                </tr>
                <tr>
                  <td className="py-1 pr-2"></td>
                  <td className="text-right">{data.lastUsed}</td>
                </tr>
              </tbody>
            </table>
          </>
        );
      default:
        return undefined;
    }
  };

  return !value || value.state === 'unverified' ? undefined : (
    <div ref={ref} className="flex items-center">
      <button
        className={`material-icons outline-focus cursor-pointer rounded-md p-0.5 text-white outline-offset-2 active:opacity-85`}
        style={{
          fontSize: '18px',
          backgroundColor: style[value.state].color
        }}
      >
        {style[value.state].icon}
      </button>
      {panelOpen && (
        <div className="shadow-hdr absolute right-0 top-7 z-20 min-h-20 bg-white p-4 text-sm">
          {buildContent(props.value)}
        </div>
      )}
    </div>
  );
};
