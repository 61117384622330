import { Button, GhostButton } from '../../buttons';

export interface DialogConfirmProps {
  children: React.ReactNode;
  callToAction?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
}

export const DialogConfirm = (props: DialogConfirmProps) => {
  const callToAction = props.callToAction || 'Bestätigen';
  return (
    <div className="relative h-48 w-full bg-white p-10">
      <div>{props.children}</div>
      <div className="absolute inset-x-4 bottom-4 flex justify-end gap-2">
        <GhostButton onClick={props.onCancel}>Abbrechern</GhostButton>
        <Button onClick={props.onConfirm}>{callToAction}</Button>
      </div>
    </div>
  );
};
