import { ClientBackendContext } from '@kidsmanager/ui-api';
import { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';

export interface FeatureMfaSelectProps {
  linkWithLegacy?: boolean;
}

export const FeatureMfaSelect = (props: FeatureMfaSelectProps) => {
  const navigate = useNavigate();
  const client = useContext(ClientBackendContext);

  const handleSelectEmail = async () => {
    const token = sessionStorage.getItem('mfaChallenge');
    if (!token) {
      return;
    }
    const identity = await client.auth.identity();
    const response = await fetch('/oauth2/mfa/trigger', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ type: 'email', token, identity })
    });
    if (response.ok) {
      const { challenge } = await response.json();
      sessionStorage.setItem('mfaChallenge', challenge || '');
      navigate('/login/mfa/email');
    } else {
      alert('Failed to send email');
    }
  };

  return (
    <div className="mt-10 flex flex-col gap-4">
      <Link
        to={'/login/mfa/authenticator'}
        className="cursor-pointer border-y border-dashed border-transparent py-6 hover:border-black/80 hover:bg-neutral-100"
      >
        <div className="mx-auto grid max-w-xs grid-cols-[90px_auto] items-center justify-center">
          <svg width="39" height="50" viewBox="0 0 39 50" fill="none">
            <rect x="0.5" y="0.5" width="38" height="49" stroke="black" />
            <line x1="14" y1="44.5" x2="26" y2="44.5" stroke="black" />
          </svg>
          <div className="text-left">Code von der Smartphone-App empfangen</div>
        </div>
      </Link>
      <div
        className="cursor-pointer border-y border-dashed border-transparent py-6 hover:border-black/80 hover:bg-neutral-100"
        onClick={handleSelectEmail.bind(this)}
      >
        <div className="mx-auto grid max-w-xs grid-cols-[90px_auto] items-center">
          <svg width="50" height="39" viewBox="0 0 50 39" fill="none">
            <rect
              x="0.5"
              y="38.5"
              width="38"
              height="49"
              transform="rotate(-90 0.5 38.5)"
              stroke="black"
            />
            <path d="M0.5 0.5L25.5 20.5L49.5 0.5" stroke="black" />
          </svg>
          <div className="text-left">Code per Email empfangen</div>
        </div>
      </div>
    </div>
  );
};
