import { Auth, ClientBackendProvider } from '@kidsmanager/ui-api';
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider
} from 'react-router-dom';
import { AppRoutes } from './app-routes';
import { DialogProvider } from '@kidsmanager/ui-core';
import { handleRefresh } from './app-refresh';

export const App = () => {
  const auth = new Auth(import.meta.env.VITE_KIDSMGR_CLIENT_ID);
  const router = createBrowserRouter(createRoutesFromElements(AppRoutes()));

  return (
    <ClientBackendProvider auth={auth} handleRefresh={handleRefresh}>
      <DialogProvider>
        <RouterProvider router={router} />
      </DialogProvider>
    </ClientBackendProvider>
  );
};

export default App;
