import '@kidsmanager/util-extensions';

export const isMonthInRange = (
  month: string,
  start: string,
  end: string | undefined
) => {
  if (!month) {
    return true;
  }
  const [year, monthNum] = month.split('-').map(Number);
  const [startYear, startMonth] = start.split('-').map(Number);
  if (year < startYear || monthNum < startMonth) {
    return false;
  }

  if (!end) {
    return true;
  }
  const [endYear, endMonth] = end.split('-').map(Number);
  return year <= endYear && monthNum <= endMonth;
};

export const datesOverlap = (
  values: { from: string; to?: string }[]
): boolean => {
  if (values.length <= 1) return false;

  const converted = values
    .map((x) => ({
      from: new Date(x.from.split('.').reverse().join('-')).getTime(),
      to: x.to
        ? new Date(x.to.split('.').reverse().join('-')).getTime()
        : undefined
    }))
    .sort((a, b) => (a.from > b.from ? 1 : -1));

  for (let i = 0; i < values.length - 1; i++) {
    const to = converted[i].to;
    if (to && to >= converted[i + 1].from) {
      return true;
    }
  }
  return false;
};

export const prependToList = <T extends { from: string; to?: string }[]>(
  id: string,
  values: T,
  date: Date
): T => {
  const toDate = (date: Date) =>
    date.toLocaleDateString('de-DE', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });

  const newValues = { id, from: '' };

  if (values.length > 0) {
    if (values[0].to) {
      const lastTo = new Date(values[0].to.split('.').reverse().join('-'));
      newValues.from = toDate(lastTo.addDays(1));
    } else {
      const lastFrom = new Date(values[0].from.split('.').reverse().join('-'));
      if (lastFrom >= date) {
        values[0].to = toDate(lastFrom.addDays(1));
        newValues.from = toDate(lastFrom.addDays(2));
      } else {
        values[0].to = toDate(date.addDays(-1));
        newValues.from = toDate(date);
      }
    }
  } else {
    newValues.from = toDate(date);
  }

  const update = [newValues, ...values];

  return update as T;
};
