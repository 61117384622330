import { Assignment, InMins24Hrs, ShiftScope } from '../rule-base';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isAssignment = (value: any): boolean => {
  return 'totalMins' in value;
};

export const totalMins = (value: ShiftScope): number => {
  return value.hours * 60 + value.mins + value.duration * 60;
};

export const endsIn = (value: ShiftScope): { days: number; mins: number } => {
  const mins = totalMins(value);
  const start = mins % InMins24Hrs;
  const days = (mins - start) / InMins24Hrs;
  return { days, mins: start };
};

export const containsNightShift = (
  value: Assignment | ShiftScope | null | undefined
): boolean => {
  if (!value) {
    return false;
  }
  if (isAssignment(value)) {
    const assignment = value as Assignment;
    return assignment.totalMins + assignment.duration > InMins24Hrs;
  } else {
    return totalMins(value) > InMins24Hrs;
  }
};
